import { fetchRestData } from '../../utils'
import { selectFetchError, selectFetchRequest } from '../selectors'

export const FETCH_REQUEST = 'FETCH/REQUEST'
export const FETCH_ERROR = 'FETCH/ERROR'
export const FETCH_RECEIVE = 'FETCH/RECEIVE'

export function dataReceive(id) {
  return {
    type: FETCH_RECEIVE,
    payload: {
      id,
    },
  }
}

export function dataRequest(id, req) {
  return {
    type: FETCH_REQUEST,
    payload: {
      id,
      request: req,
    },
  }
}

export function dataError(id) {
  return {
    type: FETCH_ERROR,
    payload: {
      id,
    },
  }
}

export function errorReceive(id) {
  return (dispatch, getState) => {
    const error = selectFetchError(id)(getState())
    if (error) {
      return
    }
    dispatch(dataError(id))
  }
}

/**
* A function that dispatches an action in which are fetched data from the REST APIs
*/
export function request(id, options, receiver = f => f, poll = false) {
  return async (dispatch, getState) => {
    const fetchReq = selectFetchRequest(id)(getState())

    // avoid duplicated requests
    if (!poll && fetchReq) {
      return
    }

    // since the code below blocks execution we need to register request before executing

    const req = fetchRestData(options)
      .then(json => {
        // request timeout
        if (!json.data && poll) {
          // dispatching again to fetch data
          return dispatch(request(id, options, receiver, true))
        }

        // request didn't return data
        if (!json.data) {
          return dispatch(errorReceive(id))
        }

        // dispatching received data
        dispatch(dataReceive(id))
        return dispatch(receiver(json.data))
      })
      .catch(e => {
        dispatch(errorReceive(id))
        // eslint-disable-next-line no-console
        console.error(e)
      })

    dispatch(dataRequest(id, req))
  }
}
