import { SET_USER_ID, SET_USER_COOKIES } from '../actions'
import initialState from '../state/user.state'

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER_ID: {
      const { id } = action.payload
      return { ...state, id }
    }
    case SET_USER_COOKIES: {
      const { cookies } = action.payload
      return { ...state, cookies }
    }
    default:
      return state
  }
}
