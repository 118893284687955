import queryString from 'query-string'
import {
  TRACKING_FB_Q,
  fbQ,
  VIEWER_INITIALIZED,
  VIEWER_BROWSE,
  OPEN_EXTERNAL_ENRICHMENT,
  ONETRUST_ACTIVATION_DONE,
  ON_ENRICHMENT_CTA_CLICK,
  ON_ENRICHMENT_LOADED,
} from '../../../actions'

import {
  selectViewerFlyerId,
  selectFlyerData,
  selectUserCookiesPreference,
  selectTrackingFB,
  selectRouterLocation,
} from '../../../selectors'

import { afterViewerBrowsePercentagePages } from './helpers'
import { OT_TARGETING_ADS_PREFERENCE } from '../../../../utils/consts'

const fbQHandler = () => next => action => {
  if (action.type === TRACKING_FB_Q) {
    const { args } = action.payload
    if (window.fbq) window.fbq(...args)
  }
  next(action)
}

const initializeFbPixel = () => {
  // This is used to avoid FB firing same events
  // when the user change preferences
  let isPixelInitialized = false

  return ({ getState, dispatch }) => next => action => {
    next(action)
    const state = getState()
    const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)
    // eslint-disable-next-line max-len
    const isReady = action.type === ONETRUST_ACTIVATION_DONE && !isPixelInitialized && isPreferenceExpressed

    if (isReady) {
      const fbPixels = selectTrackingFB(state)
      fbPixels.forEach(pixel => dispatch(fbQ('init', pixel)))
      dispatch(fbQ('track', 'PageView'))
      isPixelInitialized = true
    }
  }
}

const onViewerInitialized = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)
  if (action.type === VIEWER_INITIALIZED && isPreferenceExpressed) {
    const flyerId = selectViewerFlyerId(state)
    const flyer = selectFlyerData(flyerId)(state)

    const { currentPages } = action.payload.viewerState
    const { search } = selectRouterLocation(state)
    const { dc_product_id: dcProductId = null, fbclid = null } = queryString.parse(search)
    if (dcProductId) {
      dispatch(fbQ('track', 'ViewContent', {
        content_type: 'product',
        content_ids: [dcProductId],
      }))
    } else {
      dispatch(fbQ('track', 'ViewContent', {
        content_type: 'product',
        content_ids: [flyerId],
      }))
    }
    dispatch(fbQ('trackCustom', 'FBwebEventViewer', {
      flyer_id: flyerId,
      retailer_slug: flyer.retailer.slug,
      category_slug: flyer.retailer.category.slug,
    }))

    if (fbclid) {
      dispatch(fbQ('trackCustom', 'FBViewContent', {
        content_type: 'product',
        content_ids: [flyerId],
        fbclid,
      }))
    }

    currentPages.forEach(page => {
      dispatch(fbQ('trackCustom', 'FlyerPageBrowse', {
        flyer_id: flyerId,
        flyer_page: page,
        retailer_slug: flyer.retailer.slug,
        retailer_category: flyer.retailer.category.slug,
      }))
    })
  }
}

const onViewerBrowse = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

  if (action.type === VIEWER_BROWSE && isPreferenceExpressed) {
    const flyerId = selectViewerFlyerId(state)
    const flyer = selectFlyerData(flyerId)(state)

    const { currentPages } = action.payload.pagesData

    currentPages.forEach(page => {
      dispatch(fbQ('trackCustom', 'FlyerPageBrowse', {
        flyer_id: flyerId,
        flyer_page: page,
        retailer_slug: flyer.retailer.slug,
        retailer_category: flyer.retailer.category.slug,
      }))
    })
  }
}

const onViewerEnrichmentCtaClick = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

  if (action.type === ON_ENRICHMENT_CTA_CLICK && isPreferenceExpressed) {
    const { flyer_id: flyerId, settings: { flyer_page: flyerPage } } = action.payload.enrichment
    const flyer = selectFlyerData(flyerId)(state)

    dispatch(fbQ('trackCustom', 'ExternalLinkClicked', {
      flyer_page: flyerPage,
      retailer_slug: flyer.retailer.slug,
      retailer_category: flyer.retailer.category.slug,
    }))
  }
}

const onViewerEnrichmentLoaded = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

  if (action.type === ON_ENRICHMENT_LOADED && isPreferenceExpressed) {
    const flyerId = selectViewerFlyerId(state)
    const flyer = selectFlyerData(flyerId)(state)
    const { enrichment } = action.payload
    const productData = enrichment.product
    const params = {
      content_type: 'product',
      content_ids: enrichment.id,
      value: enrichment.settings.price_extended?.digits,
      // eslint-disable-next-line camelcase
      currency: enrichment.settings.price_extended?.currency_code,
      content_name: enrichment.title,
      content: {
        flyer_id: enrichment.flyer_id,
        retailer_category: flyer.retailer.category.slug,
        retailer_slug: flyer.retailer.slug,
      },
    }

    if (productData) {
      dispatch(fbQ('track', 'AddToCart', {
        ...params,
        content_category: productData.category ? productData.category.name : null,
        content: {
          product_id: productData.id,
          product_name: productData.title,
          product_brand: productData.brand ? productData.brand.name : null,
          product_category: productData.category ? productData.category.name : null,
          ...params.content,
        },
      }))
    } else {
      dispatch(fbQ('track', 'AddToCart', params))
    }
  }
}

/**
 * On click on an external enrichment (type === external_link).
 * The OPEN_EXTERNAL_ENRICHMENT payload must always contain a valid enrichment
 */
const onViewerEnrichmentClick = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

  if (
    action.type === OPEN_EXTERNAL_ENRICHMENT
    && isPreferenceExpressed
  ) {
    const flyerId = selectViewerFlyerId(state)
    const flyer = selectFlyerData(flyerId)(state)

    const { flyerPage } = action.payload.enrichment

    dispatch(fbQ('trackCustom', 'ExternalLinkClicked', {
      flyer_page: flyerPage,
      retailer_slug: flyer.retailer.slug,
      retailer_category: flyer.retailer.category.slug,
    }))
  }
}

const eventAfterPercentagePages = goalPercentage => (
  afterViewerBrowsePercentagePages(goalPercentage, ({ getState, dispatch }) => {
    const state = getState()
    const flyerId = selectViewerFlyerId(state)
    const flyer = selectFlyerData(flyerId)(state)

    dispatch(fbQ('trackCustom', 'FlyerPageBrowsePercentage', {
      flyer_id: flyerId,
      browse_percentage: goalPercentage,
      retailer_slug: flyer.retailer.slug,
      retailer_category: flyer.retailer.category.slug,
    }))
  })
)

export default [
  fbQHandler,
  initializeFbPixel(),
  onViewerInitialized,
  onViewerBrowse,
  onViewerEnrichmentClick,
  onViewerEnrichmentLoaded,
  onViewerEnrichmentCtaClick,
  eventAfterPercentagePages(30),
  eventAfterPercentagePages(50),
  eventAfterPercentagePages(80),
]
