import { UX_EVENT } from '../../actions'

export default (eventOptions, handler) => store => next => action => {
  next(action)

  if (action.type === UX_EVENT) {
    const { event } = action.payload
    let handle = true
    Object
      .keys(eventOptions)
      .forEach(key => {
        if (event[key] !== eventOptions[key]) handle = false
      })

    if (handle) handler(store, action)
  }
}
