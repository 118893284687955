import {
  SET_FLYER,
  SET_FLYERS,
  SET_RELATED_FLYERS,
  SET_FLYER_STORES,
} from '../actions'
import initialState from '../state/flyers.state'

export default function (state = initialState, action) {
  const { list, related, stores } = state

  switch (action.type) {
    case SET_FLYER: {
      const { flyer } = action.payload
      list[flyer.id] = flyer
      return { ...state, list }
    }

    case SET_RELATED_FLYERS: {
      const { id, related: relatedInPayload } = action.payload
      related[id] = relatedInPayload
      return { ...state, related }
    }

    case SET_FLYERS: {
      const { flyers } = action.payload
      const newList = flyers.reduce(
        (newFlyers, newFlyer) => ({ ...newFlyers, [newFlyer.id]: newFlyer }),
        list,
      )
      return { ...state, list: newList }
    }

    case SET_FLYER_STORES: {
      const { stores: storesList, flyerId } = action.payload
      const flyerStores = storesList.map(store => store.id)

      return { ...state, stores: { ...stores, [flyerId]: flyerStores } }
    }

    default:
      return state
  }
}
