export const selectViewerInitialized = state => state.viewer.initialized

export const selectViewerFlyerId = state => state.viewer.flyerId

export const selectViewerFlyerPage = state => state.viewer.flyerPage

export const selectViewerCurrentPages = state => state.viewer.currentPages

export const selectViewerNumberOfPages = state => state.viewer.numberOfPages

export const selectTableOfContentsTooltipEnabled = state => {
  const { viewer: { isTableOfContentsTooltipEnabled } } = state
  return isTableOfContentsTooltipEnabled
}
