/**
 *
 * @param {String} name Name of process.env variable
 * @param {Boolean} required specifies if the env variable is mandatory,
 * if required is true but the variable is not present in the process.env, throws an exception
 *
 * @returns {String} process.env[name]
 */
const getEnvVar = (name, required = true) => {
  if (!process.env[name] && required) { throw new Error(`process.env.${name} required`) }

  return process.env[name]
}

export const getEnvFeatureToggle = name => Boolean(Number.parseInt(getEnvVar(name), 10))

export default getEnvVar
