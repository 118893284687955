export default {
  en_au: {
    cookiebar: {
      content: '<b>Your privacy is important to us.</b> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner by clicking on the “X”, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: '&#215;',
    },
    flyer: 'catalogue',
    otherDeals: 'Other {{category}} deals',
    allAbout: 'Everything about',
    show: 'Show',
    expiringStatus: {
      new: 'New',
      'coming-soon': 'Coming soon',
    },
    day: 'day',
    days: 'days',
    disclaimer: 'ShopFully is not responsible for the content within the catalogues.<br/>If there is different information in this section, when compared to the catalogue, then what is indicated in the catalogue should prevail.',
  },
  en_nz: {
    cookiebar: {
      content: '<b>Your privacy is important to us.</b><br/><br/> We use technical cookies on our site. With some selected partners and with your prior consent, we also use profiling cookies, to analyze your behavior and to provide you content in line with your preferences. By clicking on “Accept all” you give your consent and you can view content and advertisements relevant to your interests. You can choose your preferences by clicking on “Manage options”. If you close this banner, you continue browsing without profiling cookies being installed, but the content of the site and the advertisements within will be generic and not in line with your interests. For more information visit our %{privacy_policy} and %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie policy',
      customizeButtonCaption: 'Manage options',
      privacyPolicyCaption: 'Privacy policy',
      acceptButtonCaption: 'Accept all',
      closeButtonCaption: 'Proceed without accepting',
    },
    flyer: 'catalogue',
    otherDeals: 'Other {{category}} deals',
    allAbout: 'Everything about',
    show: 'Show',
    expiringStatus: {
      new: 'New',
      'coming-soon': 'Coming soon',
    },
    day: 'day',
    days: 'days',
    disclaimer: 'ShopFully is not responsible for the content within the catalogues.<br/>If there is different information in this section, when compared to the catalogue, then what is indicated in the catalogue should prevail.',
  },
  es: {
    cookiebar: {
      content: '<b>Tu privacidad es importante para nosotros.</b></br></br>En nuestro sitio utilizamos cookies técnicas y, con algunos partner seleccionados y tu consentimiento previo, también cookies de perfil, para analizar tu comportamiento y ofrecerte contenidos que respondan a tus preferencias. Clicando en “Acepta todo” das tu consentimiento y puedes visualizar contenidos y anuncios publicitarios inherentes a tus intereses. En cualquier momento podrás especificar tus preferencias clicando en “Gestiona opciones”. Al cerrar este banner continúas navengando sin haber instalado las cookies de perfil, es decir, el contenido del sitio y los anuncios publicitarios se basarán en argumentos generales que probablemente no responden a tus intereses. Para mayor información visita nuestra %{privacy_policy} y %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Política de Cookies',
      customizeButtonCaption: 'Gestiona opciones',
      privacyPolicyCaption: 'Política de Privacidad',
      acceptButtonCaption: 'Acepta todo',
      closeButtonCaption: 'Continúa navegando sin aceptar',
    },
    flyer: 'oferta',
    otherDeals: 'Otras ofertas de {{category}}',
    allAbout: 'Todo sobre',
    show: 'Mostrar',
    expiringStatus: {
      new: 'Nuevo',
      'coming-soon': 'Próximamente',
    },
    day: 'día',
    days: 'días',
    disclaimer: 'ShopFully no se responsabiliza del contenido indicado en los folletos.<br/>En caso de incrongruencias entre esta sección y el folleto, prevalece el contenido del folleto.',
  },
  it: {
    cookiebar: {
      content: '<b>La tua privacy è importante per noi.</b></br></br>Utilizziamo nel nostro sito cookie tecnici e, con alcuni partner selezionati e previo tuo consenso, anche cookie di profilazione, per effettuare analisi sul tuo comportamento e fornirti contenuti in linea con le tue preferenze. Cliccando su “Accetta tutto” dai il consenso e puoi visualizzare contenuti e annunci pubblicitari attinenti ai tuoi interessi. In ogni momento puoi specificare le tue preferenze cliccando su “Gestisci opzioni”. Se chiudi questo banner continui la navigazione senza che vengano installati cookie di profilazione, ma il contenuto del sito e gli annunci pubblicitari riguarderanno argomenti generici non in linea con i tuoi interessi. Per maggiori informazioni visita la nostra %{privacy_policy} e %{cookie_policy_link}.',
      cookiePolicyLinkCaption: 'Cookie Policy',
      customizeButtonCaption: 'Gestisci opzioni',
      privacyPolicyCaption: 'Privacy Policy',
      acceptButtonCaption: 'Accetta tutto',
      closeButtonCaption: 'Continua senza accettare',
    },
    flyer: 'volantino',
    otherDeals: 'Altre offerte di {{category}}',
    allAbout: 'Tutto su',
    show: 'Mostra',
    expiringStatus: {
      new: 'Nuovo',
      'coming-soon': 'In Arrivo',
    },
    day: 'giorno',
    days: 'giorni',
    disclaimer: 'DoveConviene non è responsabile del contenuto indicato nei volantini.<br/>In caso di differenti informazioni tra questa sezione e il volantino, prevale quanto indicato nel volantino.',
  },
  'pt-BR': {
    cookiebar: {
      content: '<b>A sua privacidade é importante para nós.</b></br></br>.No nosso site utilizamos cookies técnicos e, com alguns parceiros selezionados e com o seu prévio consentimento, utilizamos também cookies de segmentação, para efetuar uma análise do seu comportamento e fornecer a você conteúdos relacionados às suas preferências. Clicando em ‘’Aceitar todos’’ você autoriza e poderá visualizar conteúdos e  anúncios publicitários pertinentes ao seu interesse. Você pode, a qualquer momento, especificar as suas preferências acessando a opção ‘’ Gerenciar opções’’. Se você fechar esse banner irá continuar a sua navegação sem a instalação de cookies de segmentação, mas o conteúdo do site e os anúncios publicitários serão sobre assuntos genéricos e não pertinentes ao seu interesse. Para maiores informações visite a nossa %{privacy_policy} e %{cookie_policy_link}',
      cookiePolicyLinkCaption: 'Politica de Cookies',
      customizeButtonCaption: 'Gerenciar opções',
      privacyPolicyCaption: 'Politica de Privacidade',
      acceptButtonCaption: 'Aceitar todos',
      closeButtonCaption: 'Continuar navegando sem aceitar',
    },
    flyer: 'promocoes',
    otherDeals: 'Outras ofertas de {{category}}',
    allAbout: 'Tudo sobre',
    show: 'Mostrar',
    expiringStatus: {
      new: 'Novo',
      'coming-soon': 'Em breve',
    },
    day: 'dia',
    days: 'dias',
    disclaimer: 'Shopfully não se responsabiliza pelo conteúdo presente nos encartes.<br/>No caso exista alguma diferença de informação entra essa seção e o encarte, prevalece quanto indicado no encarte.',
  },
}
