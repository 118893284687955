import styled from '@emotion/styled'

import Spinner from './Spinner'

const StyledRoot = styled.div({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
})

const Loading = () => (
  <StyledRoot>
    <Spinner />
  </StyledRoot>
)

export default Loading
