import get from 'lodash.get'

const BASE_POLYFILL_URL = 'https://polyfill.io/v3/polyfill.js'

const BROWSER_FEATURES = [
  'Number.isNaN',
  'Number.parseInt',
  'Number.isInteger',
  'String.prototype.includes',
  'String.prototype.repeat',
  'Array.prototype.find',
  'Array.prototype.entries',
  'Array.prototype.includes',
  'Array.prototype.findIndex',
  'Array.from',
  'Object.assign',
  'Object.values',
  'Object.entries',
  'Promise',
  'fetch',
  'Symbol',
  'Symbol.for',
  'Symbol.asyncIterator',
  'IntersectionObserver',
  'CustomEvent',
  'Math.trunc',
  'URL',
  'WeakMap',
]

export const getPolyFillUrl = () => {
  const featureList = BROWSER_FEATURES.reduce((acc, val) => `${acc},${val}`, '')
  return `${BASE_POLYFILL_URL}?features=${featureList}`
}

export const isBrowserUnsupported = () => BROWSER_FEATURES.some(val => !get(window, val))
