// utils
import { detectMobileBrowser } from '../../utils'

const clientState = {
  mobile: detectMobileBrowser(),
  viewport: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
}

export default clientState
