export const SET_FLYER = 'FLYER/SET_FLYER'
export const SET_RELATED_FLYERS = 'FLYER/SET_RELATED_FLYERS'

export const SET_FLYERS = 'FLYERS/SET_FLYERS'
export const SET_FLYER_STORES = 'FLYERS/SET_FLYER_STORES'

export const setFlyerStores = (flyerId, stores) => ({
  type: SET_FLYER_STORES,
  payload: {
    flyerId,
    stores,
  },
})

export const setFlyer = flyer => ({
  type: SET_FLYER,
  payload: {
    flyer,
  },
})

export const setFlyers = flyers => ({
  type: SET_FLYERS,
  payload: {
    flyers,
  },
})

export const setRelatedFlyers = (id, relatedFlyerIds) => ({
  type: SET_RELATED_FLYERS,
  payload: {
    id,
    related: relatedFlyerIds,
  },
})
