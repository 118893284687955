import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'

import { createBrowserHistory } from 'history'

import initialState from './state'
import createRootReducer from './reducers'

import customMiddlewares from './middlewares'
// import { errorBoundary } from './middlewares/helpers/errorBoundary'

export const history = createBrowserHistory()

// https://github.com/zalmoxisus/redux-devtools-extension
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
// export config store fn
export default function configureStore() {
  const middlewares = applyMiddleware(
    // errorBoundary, todo rimosso error tracking per risolvere i numerosi errori su sentry
    routerMiddleware(history),
    thunk,
    ...customMiddlewares,
  )

  const store = createStore(
    createRootReducer(history),
    initialState,
    composeEnhancers(middlewares),
  )

  return store
}
