import {
  TABLE_OF_CONTENTS_TOOLTIP_ACTION_CLICK,
} from '../../actions'

import { takeEvery } from '../helpers'
import { webStorage } from '../../../utils'
import { TABLE_OF_CONTENTS_TOOLTIP_KEY } from '../../../utils/consts'

const onCloseTableOfContentsTooltip = () => webStorage.setItem(TABLE_OF_CONTENTS_TOOLTIP_KEY, true)

export default [
  takeEvery(TABLE_OF_CONTENTS_TOOLTIP_ACTION_CLICK, onCloseTableOfContentsTooltip),
]
