import {
  selectFetchRequest, selectFetchResponse, selectFetchError, selectRetailer,
} from '.'
import { flyersRequestId } from '../middlewares/api/flyers'

export const selectFlyer = flyerId => state => state.flyers?.list[flyerId]

export const selectFlyerData = flyerId => state => {
  const { flyers: { list } } = state
  const flyer = list[flyerId]
  if (!flyer) return null

  const retailer = flyer.retailer_id ? selectRetailer(flyer.retailer_id)(state) : {}
  if (!retailer) return null

  return { ...flyer, retailer }
}

export const selectFlyerById = flyerId => state => {
  const rId = flyersRequestId(flyerId),
    request = selectFetchRequest(rId)(state),
    response = selectFetchResponse(rId)(state),
    error = selectFetchError(rId)(state),
    data = selectFlyerData(flyerId)(state)

  return {
    fetching: Boolean(request), fetched: Boolean(response), error: Boolean(error), data,
  }
}

export const selectRelatedFlyersIds = flyerId => state => {
  const { flyers } = state
  const relatedFlyersInFlyer = flyers.related[flyerId]

  return relatedFlyersInFlyer
}

export const selectRelatedFlyers = flyerId => state => {
  const relatedFlyersIds = selectRelatedFlyersIds(flyerId)(state)

  return (
    relatedFlyersIds
      ? relatedFlyersIds.map(id => selectFlyerData(id)(state))
      : [...Array(16)]
  )
}

export const selectFlyerStores = flyerId => state => {
  const flyerStores = state.flyers.stores[flyerId]
  if (!flyerStores) return null
  const { list: storesList } = state.stores

  return flyerStores.map(el => storesList[el])
}

export const selectNearestFlyerStoreId = flyerId => state => {
  const flyerStores = state.flyers.stores[flyerId]
  return flyerStores ? flyerStores[0] : null
}
