export const ON_ENRICHMENT_CLICK = 'ENRICHMENT/ON_ENRICHMENT_CLICK'
export const OPEN_EXTERNAL_ENRICHMENT = 'ENRICHMENT/OPEN_EXTERNAL_ENRICHMENT'
export const ON_ENRICHMENT_CLOSE = 'ENRICHMENT/ON_ENRICHMENT_CLOSE'
export const SET_ACTIVE_ENRICHMENT = 'ENRICHMENT/SET_ACTIVE_ENRICHMENT'
export const SET_NEXT_ENRICHMENT = 'ENRICHMENT/SET_NEXT_ENRICHMENT'
export const REMOVE_ACTIVE_ENRICHMENT = 'ENRICHMENT/REMOVE_ACTIVE_ENRICHMENT'
export const ON_ENRICHMENT_CTA_CLICK = 'ENRICHMENT/ON_ENRICHMENT_CTA_CLICK'
export const ON_ENRICHMENT_LOADED = 'ENRICHMENT/ON_ENRICHMENT_LOADED'

export const ON_ENRICHMENT_VIDEO_START = 'ENRICHMENT/ON_ENRICHMENT_VIDEO_START'
export const ON_ENRICHMENT_VIDEO_PROGRESS = 'ENRICHMENT/ON_ENRICHMENT_VIDEO_PROGRESS'

export const ON_ENRICHMENT_DRAWER_ACTION = 'VIEWER/ENRICHMENT_DRAWER_ACTION'

/**
 * Fired whenever an enrichment is clicked on (whatever the type)
 * @param {Object} enrichment - The flyerGib object
 */
export const onEnrichmentClick = enrichment => ({
  type: ON_ENRICHMENT_CLICK,
  payload: {
    enrichment,
  },
})

/**
 * Fired whenever an enrichment and his drawer are closed
 */
export const onEnrichmentClose = () => ({
  type: ON_ENRICHMENT_CLOSE,
})

/**
 * Fired whenever the clicked enrichment is of type === external_link
 * Used to open the link of the external enrichment
 * @param {Object} enrichment - The flyerGib object
 */
export const openExternalEnrichment = enrichment => ({
  type: OPEN_EXTERNAL_ENRICHMENT,
  payload: {
    enrichment,
  },
})

/**
 * Fired whenever the clicked enrichment is internal
 * Used to set the current flyerGib on the redux store
 * When an enrichment is already open, setNextEnrichment will be dispatched
 * @param {Object} enrichment - The flyerGib object
 */
export const setActiveEnrichment = enrichment => ({
  type: SET_ACTIVE_ENRICHMENT,
  payload: {
    enrichment,
  },
})

/**
 * Used to set the next flyerGib on the redux store
 * Added after drawer implementation
 * Needed to wait after previous flyerGib (loaded inside drawer) has been closed
 * before loading the new one
 * @param {Object} enrichment - The flyerGib object
 */
export const setNextEnrichment = enrichment => ({
  type: SET_NEXT_ENRICHMENT,
  payload: {
    enrichment,
  },
})

/**
 * Used to reset the active enrichment on the redux store to its initial value (NULL)
 * Fired whenever an enrichment and his drawer are closed
 */
export const removeActiveEnrichment = () => ({
  type: REMOVE_ACTIVE_ENRICHMENT,
  payload: {},
})

/**
 * Fired whenever the clicked enrichment is of type === external_link
 * @param {Object} enrichment - The flyerGib object
 * @param {Object} cta - { text, url, store_id }
 */
export const onEnrichmentCtaClick = (enrichment, cta) => ({
  type: ON_ENRICHMENT_CTA_CLICK,
  payload: {
    enrichment,
    cta,
  },
})

/**
 * Fired when the clicked enrichment is of type === external_link
 * @param {Object} enrichment - The flyerGib object
 */
export const onEnrichmentLoaded = enrichment => ({
  type: ON_ENRICHMENT_LOADED,
  payload: {
    enrichment,
  },
})

/**
 * Fired when the video is started
 * @param {Number} fid - The flyer Id
 * @param {String} fgid - The flyerGib Id
 */
export const onEnrichmentVideoStart = (fid, fgid) => ({
  type: ON_ENRICHMENT_VIDEO_START,
  payload: { fid, fgid },
})

/**
 * Fired while the video is playing and reaching specific percentages
 * @param {Number} fid - The flyer Id
 * @param {String} fgid - The flyerGib Id
 * @param {Array.<Number>} step - List of the percentage of video displayed to track
 * @param {Boolean} seeked - Indicates if the user has manually fast-forwarded the video
 */
export const onEnrichmentVideoProgress = (fid, fgid, step, seeked) => ({
  type: ON_ENRICHMENT_VIDEO_PROGRESS,
  payload: {
    fid, fgid, step, seeked,
  },
})

/**
 * Fired whenever the user perform an action which involves the enrichment drawer
 * @param {Number} fid - The flyer Id
 * @param {String} fgid - The flyerGib Id
 * @param {String} action - The type of action (see DRAWER_ACTIONS for full list)
 */
export const onEnrichmentDrawerAction = (fid, fgid, action) => ({
  type: ON_ENRICHMENT_DRAWER_ACTION,
  payload: {
    fid, fgid, action,
  },
})
