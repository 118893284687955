import queryString from 'query-string'

import createTrackingMeta from '@doveconviene/sf-web-js-sdk/createTrackingMeta'
import sanitizeObject from '@doveconviene/sf-web-js-sdk/sanitizeObject'
import UAParser from 'ua-parser-js'
import { takeEvery, handleUXEvent } from '../../helpers'
import getCookie from '../../../../utils/getCookies'

import {
  PANEL_CROSSELL,
  TRACKING_SPLUNK,
  TRACKING_SPLUNK_POST,
  VIEWER_INITIALIZED,
  VIEWER_BROWSE,
  VIEWER_EXIT,
  trackSplunk,
  trackSplunkPost,

  // Table of Contents
  sendTableOfContentsImpression,
  LAYOUT_OPENFULLSCREEN,
  TABLE_OF_CONTENTS_TOGGLE_CLICK,
  TABLE_OF_CONTENTS_ITEM_CLICK,

  // Enrichment
  SET_ACTIVE_ENRICHMENT,
  OPEN_EXTERNAL_ENRICHMENT,
  ON_ENRICHMENT_CTA_CLICK,
  ON_ENRICHMENT_VIDEO_START,
  ON_ENRICHMENT_VIDEO_PROGRESS,
  ON_ENRICHMENT_DRAWER_ACTION,
} from '../../../actions'
import {
  selectUser,
  selectRouterLocation,
  selectViewerCurrentPages,
  selectViewerFlyerId,
  selectFlyerData,
  selectUserGeolocation,
  selectUserId,
  selectIsPreview,
  selectUserCookiesPreference,
} from '../../../selectors'

import { OT_TARGETING_ADS_PREFERENCE } from '../../../../utils/consts'
import { env, compileTemplate } from '../../../../utils'

const logPassword = env('REACT_APP_LOG_PASSWORD')

export const trackSplunkApi = entries => {
  const { REACT_APP_SPLUNK_URL } = process.env
  return fetch(`${REACT_APP_SPLUNK_URL}/?${queryString.stringify(entries)}`)
}

export const allowedEntries = [
  'a',
  'ts',
  'c',
  't',
  'z',
  'uip',
  'lat',
  'lng',
  'lm',
  'la',
  'st',
  'et',
  'fid',
  'fp',
  'fvk',
  'utm_source',
  'dcid',
  'utm_medium',
  'sp',
  'utm_content',
  'utm_campaign',
  'utm_term',
  'property_id',
  'gclid',
  'fbclid',
  'profiling_consent',
  'fbp',
]

export const structureEntries = (entries, meta) => sanitizeObject({
  ...entries,
  ...meta,
}, allowedEntries)

const onSplunkTrack = ({ getState }, { payload }) => {
  const state = getState()
  const { entries } = payload

  // get fbp cookie of facebook
  const fbp = getCookie('_fbp')
  const fbpParam = fbp ? { fbp } : {}

  const { geolocation, id: userId } = selectUser(state)
  const { search } = selectRouterLocation(state)
  const isPreview = selectIsPreview(state)
  const country = env('REACT_APP_COUNTRY')
  // eslint-disable-next-line camelcase
  const queryStringParams = queryString.parse(search)

  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)
  const structuredEntries = structureEntries({
    ...entries,
    c: country,
    dcid: userId,
    utm_source: 'direct',
    profiling_consent: isPreferenceExpressed,
    ...queryStringParams,
    ...geolocation,
    ...fbpParam,
  }, createTrackingMeta(logPassword))

  if (!isPreview) {
    trackSplunkApi(structuredEntries)
  }
}

const trackPagesActions = (pages, entries) => (
  pages.reduce((actions, page) => ([
    ...actions, trackSplunk({
      ...entries,
      fp: page,
    }),
  ]), [])
)

const baseEntries = {
  t: 'w',
  lm: 'b',
  la: 0,
}

const onViewerInitialized = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === VIEWER_INITIALIZED) {
    const { currentPages, flyerId } = action.payload.viewerState

    trackPagesActions(currentPages, {
      ...baseEntries,
      fid: flyerId,
      a: 'fv',
      fvk: 's',
    }).forEach(trackAction => dispatch(trackAction))

    trackPagesActions(currentPages, {
      ...baseEntries,
      fid: flyerId,
      a: 'fv',
      fvk: 'm',
    }).forEach(trackAction => dispatch(trackAction))
  }
}

const onViewerBrowse = ({ getState, dispatch }) => {
  const state = getState()

  const currentPages = selectViewerCurrentPages(state)
  const flyerId = selectViewerFlyerId(state)

  trackPagesActions(currentPages, {
    ...baseEntries,
    fid: flyerId,
    a: 'fv',
    fvk: 'm',
  }).forEach(action => dispatch(action))
}

const onViewerExit = ({ getState, dispatch }) => {
  const state = getState()

  const currentPages = selectViewerCurrentPages(state)
  const flyerId = selectViewerFlyerId(state)

  trackPagesActions(currentPages, {
    ...baseEntries,
    fid: flyerId,
    a: 'fv',
    fvk: 'e',
  }).forEach(action => dispatch(action))
}

const onFlyerCardClickInCrossell = ({ getState, dispatch }, { payload }) => {
  const state = getState()
  const { event: { flyerId, utmMediumValue } } = payload
  const { spotlight } = selectFlyerData(flyerId)(state)

  if (spotlight) {
    const { landing_url: landingUrl } = spotlight

    const {
      utm_medium: utmMedium = utmMediumValue || 'cs',
      utm_source: utmSource = 'webslim',
    } = queryString.parse(selectRouterLocation(state).search)

    const geolocation = selectUserGeolocation(state)
    const userId = selectUserId(state)

    const urlToOpen = compileTemplate(landingUrl, {
      ...geolocation,
      utm_source: utmSource,
      utm_medium: utmMedium,
      muid: userId,
    })

    window.open(urlToOpen, '_blank')

    dispatch(trackSplunk({
      ...baseEntries,
      fid: flyerId,
      a: 'ov',
      fvk: 'w',
    }))
  }
}

const onFlyerCardImpressionInCrossell = ({ getState, dispatch }, { payload }) => {
  const state = getState()
  const { event: { flyerId, order, utmm = '' } } = payload
  const flyer = selectFlyerData(flyerId)(state)

  dispatch(trackSplunkPost('fi', {
    fid: flyerId,
    ed: '',
    fo: order,
    ft: flyer.type,
    fw: flyer.weight,
    la: '',
    lm: '',
    utmm,
  }))
}

const onPostSplunkTrack = ({ getState }, { payload }) => {
  const state = getState()
  const { eventType, at } = payload
  if (at.fid && !Number.isNaN(at.fid)) {
    at.fid = at.fid.toString()
  }

  const {
    utm_medium: utmm = '',
    utm_source: utms = 'direct',
    utm_campaign: utmc = '',
  } = queryString.parse(selectRouterLocation(state).search)
  const country = env('REACT_APP_COUNTRY_ISO3166_1')
  const splunkUrl = env('REACT_APP_SPLUNK_URL')
  const { lat, lng } = selectUserGeolocation(state)
  const fid = selectViewerFlyerId(state).toString()
  const uid = selectUserId(state)
  const ua = UAParser()
  const { name: brn, version: brv } = ua.browser
  const { name: sn, version: sv } = ua.os
  const { st, ts, et } = createTrackingMeta(logPassword)

  const eventData = {
    h: {
      brn,
      brv,
      ct: country,
      pid: '_dc_',
      pn: 'webslim',
      sn,
      sv,
      tp: 'w',
      uid,
    },
    v: '1.0',
    e: [
      {
        at: {
          utms: utms !== 'undefined' ? utms : undefined,
          utmm: utmm !== 'undefined' ? utmm : at?.utmm,
          utmc: utmc !== 'undefined' ? utmc : undefined,
          fid,
          lat,
          lng,
          ...at, // fp, fgid, text,url
        },
        et: eventType,
        ts,
      },
    ],
  }

  return fetch(`${splunkUrl}/?et=${et}&st=${st}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    body: JSON.stringify(eventData),
  })
}

const onEnrichmentCtaClick = ({ dispatch }, { payload: { enrichment, cta } }) => {
  const { id: fgid, settings: { flyer_page: fp } } = enrichment
  // eslint-disable-next-line camelcase
  const {
    text, url, store_id: storeId,
  } = cta

  dispatch(trackSplunkPost('fgb', {
    fp, fgid, text, url, store_id: storeId ? storeId.toString() : undefined,
  }))
}

/**
 * @param {Object} payload.enrichment
 */
const onEnrichmentOpen = ({ dispatch }, { payload: { enrichment } }) => {
  const { id: fgid, flyerPage: fp } = enrichment

  dispatch(trackSplunkPost('fg', {
    fp, fgid,
  }))
}

const onTableOfContentsFirstInitialized = ({ getState, dispatch }) => {
  const state = getState()
  const {
    viewer: { currentPages: [fp], flyerId: fid, viewerHasTableOfContents },
    tracking: { tableOfContentImpressionDone },
  } = state

  if (!tableOfContentImpressionDone && viewerHasTableOfContents && fp && fid) {
    dispatch(trackSplunkPost('bi', { fid, fp, act: 'i' }))
    dispatch(sendTableOfContentsImpression())
  }
}

const onTableOfContentsToggle = ({ getState, dispatch }, { payload }) => {
  const state = getState()
  const {
    viewer: { currentPages: [fp], flyerId: fid, retailerId },
  } = state
  const { isOpenTableOfContent } = payload

  if (isOpenTableOfContent) {
    dispatch(trackSplunkPost('bi', { fid, fp, act: 'c' }))
    dispatch(trackSplunkPost('ei', {
      fid, fp, retailer_id: retailerId, act: 'i',
    }))
  }
}

const onTableOfContentsItemClick = ({ getState, dispatch }, { payload }) => {
  const state = getState()
  const { viewer: { currentPages: [fp], flyerId: fid, retailerId } } = state
  const { tableOfContentsItemLabel } = payload

  dispatch(trackSplunkPost('ei', {
    fid, fp, act: 'c', index_entry: tableOfContentsItemLabel, retailer_id: retailerId,
  }))
}

const onEnrichmentVideoStart = ({ dispatch }, { payload: { fid, fgid } }) => {
  dispatch(trackSplunkPost('pv', { fid, fgid, origin: 'v' }))
}

const onEnrichmentVideoProgress = ({ dispatch }, {
  payload: {
    fid,
    fgid,
    step,
    seeked,
  },
}) => {
  dispatch(trackSplunkPost('vv', {
    fid,
    fgid,
    pvv: step,
    mode: seeked ? 'ff' : 'noff',
  }))
}

const onEnrichmentDrawerAction = ({ dispatch }, { payload: { fid, fgid, action } }) => {
  dispatch(trackSplunkPost('fg_e', {
    fid,
    fgid,
    event_action: action,
  }))
}

export default [
  takeEvery(TRACKING_SPLUNK, onSplunkTrack),
  takeEvery(TRACKING_SPLUNK_POST, onPostSplunkTrack),
  onViewerInitialized,
  takeEvery(VIEWER_BROWSE, onViewerBrowse),
  takeEvery(VIEWER_EXIT, onViewerExit),
  takeEvery([SET_ACTIVE_ENRICHMENT, OPEN_EXTERNAL_ENRICHMENT], onEnrichmentOpen),
  takeEvery(ON_ENRICHMENT_CTA_CLICK, onEnrichmentCtaClick),
  handleUXEvent({
    name: 'click',
    target: 'flyerCard',
    context: PANEL_CROSSELL,
  }, onFlyerCardClickInCrossell),
  handleUXEvent({
    name: 'impression',
    target: 'flyerCard',
    context: PANEL_CROSSELL,
  }, onFlyerCardImpressionInCrossell),

  // Table of Contents
  takeEvery(LAYOUT_OPENFULLSCREEN, onTableOfContentsFirstInitialized),
  takeEvery(TABLE_OF_CONTENTS_TOGGLE_CLICK, onTableOfContentsToggle),
  takeEvery(TABLE_OF_CONTENTS_ITEM_CLICK, onTableOfContentsItemClick),

  // ProductInfo
  takeEvery(ON_ENRICHMENT_VIDEO_START, onEnrichmentVideoStart),
  takeEvery(ON_ENRICHMENT_VIDEO_PROGRESS, onEnrichmentVideoProgress),
  takeEvery(ON_ENRICHMENT_DRAWER_ACTION, onEnrichmentDrawerAction),
]
