export const selectLayoutFullscreen = state => state.layout.fullscreen

export const selectLayoutActivePanels = state => state.layout.activePanels

export const selectIsPreview = state => state.layout.isPreview

export const selectLayout = state => ({
  fullscreen: selectLayoutFullscreen(state),
  activePanels: selectLayoutActivePanels(state),
})
