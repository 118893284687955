import { sanitizeId } from '../../../../utils'

export function sanitizeRetailerData(retailer) {
  return {
    ...retailer,
    id: sanitizeId(retailer.id),
  }
}

export function sanitizeRetailersData(retailers) {
  return retailers.map(sanitizeRetailerData)
}
