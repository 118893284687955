/** @jsxImportSource @emotion/react */
import { Component } from 'react'
import PropTypes from 'prop-types'

import equal from 'deep-equal'

class GMap extends Component {
  /**
   * Removes a single marker data
   */
  static deleteMarker(marker) {
    if (marker) {
      marker.setMap(null)
    }
  }

  constructor(props) {
    super(props)
    // init status
    // not in state to avoid dom refresh
    this.map = null
    this.markers = []
    this.infoWindows = {}
    // binding event functions
    this.onMarkerClick = this.onMarkerClick.bind(this)
    this.onInfoWindowClose = this.onInfoWindowClose.bind(this)
    this.googleMaps = window.google.maps
  }

  /**
   * Instanciates the google map and starts the markers rendering
   */
  componentDidMount() {
    this.renderMap()
    this.latLngbounds = new this.googleMaps.LatLngBounds()
    this.renderMarkers(this.props.markers)
  }

  /**
   * Updates markers when new ones get received
   */
  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ markers }) {
    if (this.props.markers.length !== markers.length) {
      this.renderMarkers(markers)
    } else if (this.props.markers !== markers) {
      this.replaceMarkers(markers)
    }
  }

  /**
   * The rendering is not performed by DOM, so we avoid updating it
   */
  shouldComponentUpdate() {
    return false
  }

  /**
   * Removes data when the component is about to be killed
   */
  componentWillUnmount() {
    this.deleteMarkers()
    this.deleteInfoWindows()
    this.map = null
  }

  /**
   * Called when an infoWindow gets closed
   */
  onInfoWindowClose(data) {
    if (this.props.onInfoWindowClose) this.props.onInfoWindowClose(data, this)
  }

  /**
   * Called when a marker gets clicked
   */
  onMarkerClick(data) {
    if (this.props.onMarkerClick) this.props.onMarkerClick(data, this)
  }

  /**
   * Adds a marker bound infoWindow
   */
  addInfoWindow(infoWindowData, index) {
    const { map, markers } = this

    const { onWindowInfoOpen, onWindowInfoOpened } = this.props
    const { config, open } = infoWindowData
    const infoWindow = new this.googleMaps.InfoWindow(config || {})

    if (open) {
      onWindowInfoOpen({
        infoWindowData, infoWindow, index, map,
      })
      infoWindow.open(map, markers[index])
      onWindowInfoOpened({
        infoWindowData, infoWindow, index, map,
      })
    }

    infoWindow.addListener('closeclick', this.onInfoWindowClose)

    return infoWindow
  }

  /**
   * Adds a single marker
   */
  addMarker(markerData) {
    const latLng = new this.googleMaps.LatLng(markerData.lat, markerData.lng)
    const { icon = null } = markerData
    const marker = new this.googleMaps.Marker({ position: latLng, map: this.map, icon })

    marker.addListener('click', () => {
      this.onMarkerClick({ markerData, marker })
    })

    this.latLngbounds.extend(latLng)

    return marker
  }

  /**
   * Adds markers
   */
  addMarkers(markers) {
    if (!markers.length) {
      return
    }

    this.markers = markers.map(this.addMarker, this)

    markers.forEach((marker, index) => {
      if (marker.infoWindow) {
        this.infoWindows[index] = this.addInfoWindow(marker.infoWindow)
      }
    }, this)
  }

  /**
   * Removes a single info window and eventual listeners
   * https://stackoverflow.com/questions/10363232/how-can-i-remove-an-infowindow-when-the-marker-is-removed
   */
  deleteInfoWindow(infoWindow) {
    if (infoWindow) {
      this.googleMaps.event.clearInstanceListeners(infoWindow)
      infoWindow.close()
      infoWindow.setMap(null)
      // eslint-disable-next-line no-param-reassign
      infoWindow = null
    }
  }

  /**
   * Removes infoWindows data
   */
  deleteInfoWindows() {
    if (!Object.keys(this.infoWindows).length) {
      return
    }

    for (const markerIndex in this.infoWindows) {
      if (Object.prototype.hasOwnProperty.call(this.infoWindows, markerIndex)) {
        this.deleteInfoWindow(this.infoWindows[markerIndex])
      }
    }

    this.infoWindows = {}
  }

  /**
   * Removes markers data
   */
  deleteMarkers() {
    if (!this.markers.length) {
      return
    }

    this.markers.forEach(GMap.deleteMarker, this)
    this.markers = []
  }

  /**
   * for each newMarker received
   * lauches replaceMarker only if the marker data is not deep-equal the old
   * lauches replaceInfoWindow only if the infoWindow data is not deep-equal the old
   */
  replaceMarkers(newMarkers) {
    newMarkers.forEach((newMarker, index) => {
      const { infoWindow, ...markerData } = this.props.markers[index]
      const { infoWindow: newInfoWindow, ...newMarkerData } = newMarker

      if (!equal(markerData, newMarkerData)) {
        this.replaceMarker(newMarker, index)
      }

      if (!equal(infoWindow, newInfoWindow)) {
        this.replaceInfoWindow(newInfoWindow, index)
      }
    }, this)
  }

  replaceMarker(newMarker, index) {
    GMap.deleteMarker(this.markers[index])
    this.markers[index] = this.addMarker(newMarker)
  }

  replaceInfoWindow(newInfoWindow, index) {
    this.deleteInfoWindow(this.infoWindows[index])
    this.infoWindows[index] = this.addInfoWindow(newInfoWindow, index)
  }

  /**
   * Renders markers data and updates map bounds to fit new ones
   */
  renderMarkers(markers) {
    this.deleteMarkers()
    this.addMarkers(markers)
    this.map.fitBounds(this.latLngbounds)
  }

  /**
   * Instanciates a new google map with dom target the canvas reference el
   * and passing this.props.mapOptions
   * it also adds listeners to the map
   */
  renderMap() {
    this.map = new this.googleMaps.Map(this.canvas, this.props.mapOptions)
  }

  render() {
    return (
      // eslint-disable-next-line react/no-unknown-property
      <div ref={node => { this.canvas = node }} css={this.props.cssClasses} />
    )
  }
}

GMap.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
    infoWindow: PropTypes.shape({}),
  })).isRequired,
  mapOptions: PropTypes.shape({}),
  onMarkerClick: PropTypes.func,
  onWindowInfoOpen: PropTypes.func,
  onInfoWindowClose: PropTypes.func,
  onWindowInfoOpened: PropTypes.func,
  cssClasses: PropTypes.shape({}),
}

GMap.defaultProps = {
  mapOptions: {},
  cssClasses: {},
  onMarkerClick: () => false,
  onWindowInfoOpen: () => false,
  onInfoWindowClose: () => false,
  onWindowInfoOpened: () => false,
}

export default GMap
