import { SET_CLIENT_VIEWPORT } from '../actions'
import initialState from '../state/client.state'

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT_VIEWPORT: {
      const { viewport } = action.payload
      return {
        ...state,
        viewport,
      }
    }
    default:
      return state
  }
}
