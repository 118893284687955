import initMiddlewares from './init.middlewares'
import applicationMiddlewares from './application.middlewares'
import oneTrustMiddlewares from './onetrust.middlewares'
import viewerMiddlewares from './viewer.middlewares'
import enrichmentMiddlewares from './enrichment.middleware'
import trackingMiddlewares from './tracking'
import layoutMiddlewares from './layout.middlewares'
import routerMiddlewares from './router.middlewares'
import uxMiddlewares from './ux.middlewares'

export default [
  ...initMiddlewares,
  ...oneTrustMiddlewares,
  ...applicationMiddlewares,
  ...viewerMiddlewares,
  ...enrichmentMiddlewares,
  ...trackingMiddlewares,
  ...layoutMiddlewares,
  ...routerMiddlewares,
  ...uxMiddlewares,
]
