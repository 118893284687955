import './css/style.css'
import ReactDOM from 'react-dom'

import App from './app'
import { errorTrackingInit } from './app/utils/errorTracking'
import { getPolyFillUrl, isBrowserUnsupported } from './app/utils/isBrowserUnsupported'
import { loadScript } from './app/utils/loadScript'

const startApp = () => {
  errorTrackingInit()

  ReactDOM.render(
    App,
    document.getElementById('root'),
  )
}

if (isBrowserUnsupported()) {
  loadScript(getPolyFillUrl(), () => { startApp() })
} else {
  startApp()
}
