import splunkMiddlewares from './splunk.middlewares'
import gtagMiddlewares from './gtag.middlewares'
import adwordsMiddlewares from './adwords.middlewares'
import analyticsMiddlewares from './analytics.middlewares'
import fbMiddlewares from './fb.middlewares'
import adformMiddlewares from './adform.middlewares'
import doubleclickMiddlewares from './doubleclick.middlewares'
import comScoreMiddlewares from './comScore.middlewares'

export default [
  ...splunkMiddlewares,
  ...gtagMiddlewares,
  ...adwordsMiddlewares,
  ...analyticsMiddlewares,
  ...fbMiddlewares,
  ...adformMiddlewares,
  ...doubleclickMiddlewares,
  ...comScoreMiddlewares,
]
