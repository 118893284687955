import {
  TRACKING_COMSCORE_BEACON,
  comScoreBeacon,
  ONETRUST_ON_READY,
} from '../../../actions'
import {
  selectTrackingComScore,
  selectIsCookiePreferenceExpressed,
} from '../../../selectors'

import { OT_TARGETING_ADS_PREFERENCE } from '../../../../utils/consts'
import { takeEvery } from '../../helpers'
import { isPreferenceActive } from '../../../../utils'

const comScoreBeaconHandler = (store, action) => {
  if (action.type === TRACKING_COMSCORE_BEACON) {
    const { args } = action.payload

    if (window.COMSCORE && window.COMSCORE.beacon) window.COMSCORE.beacon(...args)
  }
}

const initializeComScore = ({ getState, dispatch }) => {
  const state = getState()
  // eslint-disable-next-line no-underscore-dangle
  const isTargetingAdsAccepted = isPreferenceActive(OT_TARGETING_ADS_PREFERENCE)
  const isCookiePreferenceExpressed = selectIsCookiePreferenceExpressed(state)
  const { c1, c2 } = selectTrackingComScore(state)

  if (c1 && c2) {
    dispatch(comScoreBeacon({
      c1,
      c2,
      cs_ucfr: (() => {
        // 1 == consent given  - 0 == rejected - '' == consent still not given
        if (isTargetingAdsAccepted) return 1
        if (isCookiePreferenceExpressed) return 0
        return ''
      })(),
    }))
  }
}

export default [
  takeEvery(TRACKING_COMSCORE_BEACON, comScoreBeaconHandler),
  takeEvery(ONETRUST_ON_READY, initializeComScore),
]
