const viewerState = {
  initialized: false,
  flyerId: null,
  publicationId: null,
  slide: 1,
  currentPages: [],
  numberOfPages: 0,
  isLast: false,
  zoom: false,
}

export default viewerState
