export const SET_CLIENT_VIEWPORT = 'CLIENT/SET_CLIENT_VIEWPORT'

/**
 * @param {Object} viewport
 * @param {string} viewport.width - window.innerWidth
 * @param {string} viewport.height - window.innerHeight
 * @param {string} viewport.breakpoint - window breakpoint
 */
export const setClientViewport = viewport => ({
  type: SET_CLIENT_VIEWPORT,
  payload: { viewport },
})
