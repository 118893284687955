import {
  VIEWER_INITIALIZED,
  gtag,
} from '../../../actions'
import {
  selectViewerInitialized,
  selectTrackingDoubleclick,
  selectUserCookiesPreference,
} from '../../../selectors'

import { OT_TARGETING_ADS_PREFERENCE } from '../../../../utils/consts'

const doubleclickConversion = ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === VIEWER_INITIALIZED) {
    const state = getState()
    const viewerInitialized = selectViewerInitialized(state)
    const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

    if (viewerInitialized && isPreferenceExpressed) {
      const { id, type, cat } = selectTrackingDoubleclick(state)
      dispatch(gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: `${id}/${type}/${cat}`,
      }))
    }
  }
}

export default [
  doubleclickConversion,
]
