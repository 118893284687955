export const selectUserId = state => state.user.id

export const selectUserGeolocation = state => state.user.geolocation

export const selectIsConsentGiven = state => state.user.cookies.isConsentGiven

export const selectIsCookiePreferenceExpressed = state => state.user.cookies.isPreferenceExpressed

export const selectUserCookiesPreference = type => state => (
  state.user.cookies.preferences.indexOf(type) >= 0
)

export const selectUser = state => ({
  id: selectUserId(state),
  geolocation: selectUserGeolocation(state),
})
