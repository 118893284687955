export const VIEWER_STATE_RECEIVE = 'VIEWER/STATE_RECEIVE'
export const VIEWER_INITIALIZED = 'VIEWER/INITIALIZED'
export const VIEWER_ZOOM = 'VIEWER/ZOOM'
export const VIEWER_BROWSE = 'VIEWER/BROWSE'
export const VIEWER_LAST_PAGE = 'VIEWER/LAST_PAGE'
export const VIEWER_SWIPE_LAST = 'VIEWER/SWIPE_LAST'
export const VIEWER_EXIT = 'VIEWER/EXIT'
export const SET_VIEWER_FLYER_ID = 'VIEWER/SET_VIEWER_FLYER_ID'
export const SET_VIEWER_FLYER_PAGE = 'VIEWER/SET_VIEWER_FLYER_PAGE'

// Table of Contents
export const TABLE_OF_CONTENTS_INITIALIZED = 'VIEWER/TABLE_OF_CONTENTS_INITIALIZED'
export const TABLE_OF_CONTENTS_TOGGLE_CLICK = 'VIEWER/TABLE_OF_CONTENTS_TOGGLE_CLICK'
export const TABLE_OF_CONTENTS_ITEM_CLICK = 'VIEWER/TABLE_OF_CONTENTS_ITEM_CLICK'
export const SET_TABLE_OF_CONTENTS_TOOLTIP_VISIBILITY = 'VIEWER/SET_TABLE_OF_CONTENTS_TOOLTIP_VISIBILITY'
export const TABLE_OF_CONTENTS_TOOLTIP_ACTION_CLICK = 'VIEWER/TABLE_OF_CONTENTS_TOOLTIP_ACTION_CLICK'

export const viewerInitialized = viewerState => ({
  type: VIEWER_INITIALIZED,
  payload: { viewerState },
})

export const viewerZoom = rate => ({
  type: VIEWER_ZOOM,
  payload: { rate },
})

export const viewerBrowse = pagesData => ({
  type: VIEWER_BROWSE,
  payload: { pagesData },
})

export const viewerLastPage = () => ({
  type: VIEWER_LAST_PAGE,
  payload: {},
})

export const viewerSwipeLast = () => ({
  type: VIEWER_SWIPE_LAST,
  payload: {},
})

export const receiveViewerState = viewerState => ({
  type: VIEWER_STATE_RECEIVE,
  payload: { viewerState },
})

export const viewerExit = () => ({
  type: VIEWER_EXIT,
  payload: {},
})

export const setViewerFlyerId = flyerId => ({
  type: SET_VIEWER_FLYER_ID,
  payload: { flyerId },
})

export const setViewerFlyerPage = flyerPage => ({
  type: SET_VIEWER_FLYER_PAGE,
  payload: { flyerPage },
})

// Table of Contents
export const tableOfContentsInitialized = viewerHasTableOfContents => ({
  type: TABLE_OF_CONTENTS_INITIALIZED,
  payload: { viewerHasTableOfContents },
})

export const tableOfContentsToggleClick = isOpenTableOfContent => ({
  type: TABLE_OF_CONTENTS_TOGGLE_CLICK,
  payload: { isOpenTableOfContent },
})

export const tableOfContentsItemClick = (
  tableOfContentsItemPage,
  tableOfContentsItemLabel,
) => ({
  type: TABLE_OF_CONTENTS_ITEM_CLICK,
  payload: {
    tableOfContentsItemPage, tableOfContentsItemLabel,
  },
})

export const setTableOfContentsTooltipVisibility = isTableOfContentsTooltipEnabled => ({
  type: SET_TABLE_OF_CONTENTS_TOOLTIP_VISIBILITY,
  payload: { isTableOfContentsTooltipEnabled },
})

export const tableOfContentsTooltipActionClick = () => ({
  type: TABLE_OF_CONTENTS_TOOLTIP_ACTION_CLICK,
  payload: {},
})
