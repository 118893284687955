import fetchState from './fetch.state'
import layoutState from './layout.state'
import flyersState from './flyers.state'
import retailersState from './retailers.state'
import storesState from './stores.state'
import categoriesState from './categories.state'
import viewerState from './viewer.state'
import clientState from './client.state'
import userState from './user.state'
import trackingState from './tracking.state'
import uxState from './ux.state'
import enrichmentState from './enrichment.state'

const states = {
  fetch: fetchState,
  layout: layoutState,
  flyers: flyersState,
  retailers: retailersState,
  stores: storesState,
  categories: categoriesState,
  viewer: viewerState,
  client: clientState,
  user: userState,
  tracking: trackingState,
  ux: uxState,
  enrichment: enrichmentState,
}

export default states
