export const SET_USER_ID = 'USER/SET_USER_ID'
export const SET_USER_COOKIES = 'USER/SET_USER_COOKIES'

/**
 * @param {string} id
 */
export const setUserId = id => ({
  type: SET_USER_ID,
  payload: { id },
})

/**
 * @param {boolean} cookies
 */
export const setUserCookies = cookies => ({
  type: SET_USER_COOKIES,
  payload: { cookies },
})
