import {
  PANEL_PRODUCT_DRAWER,
  openPanel,
  onEnrichmentClick as onEnrichmentClickAction,
  closePanel,

  // Enrichment
  SET_ACTIVE_ENRICHMENT,
  OPEN_EXTERNAL_ENRICHMENT,
  ON_ENRICHMENT_CLICK,
  ON_ENRICHMENT_CLOSE,
  setActiveEnrichment,
  setNextEnrichment,
  openExternalEnrichment,
  removeActiveEnrichment,
} from '../../actions'

import { takeEvery } from '../helpers'
import { fetchRestData } from '../../../utils'
import { selectActiveEnrichment, selectNextEnrichment } from '../../selectors'

/**
 * The ON_ENRICHMENT_CLICK action is fired when an enrichment is clicked
 * @param {Object} payload.enrichment
 */
const onEnrichmentClick = ({ getState, dispatch }, { payload: { enrichment } }) => {
  if (enrichment?.flyerGibType === 'external_link') {
    dispatch(openExternalEnrichment(enrichment))
  } else {
    const state = getState()

    const currentEnrichment = selectActiveEnrichment(state)
    if (!currentEnrichment) {
      dispatch(setActiveEnrichment(enrichment))
    } else {
      dispatch(setNextEnrichment(enrichment))
    }
  }
}

/**
 * The enrichment clicked on is an internal enrichment
 * (we must open the product drawer)
 */
const onOpenEnrichment = ({ dispatch }) => {
  dispatch(openPanel(PANEL_PRODUCT_DRAWER))
}

/**
 * Fired on click on enrichment of type === external_link
 * @param {Object} payload.enrichment
 */
const onOpenExternalEnrichment = (state, { payload: { enrichment = {} } }) => {
  const options = {
    resource: `flyer_gibs/${enrichment.id}`,
    params: { fields: 'settings' },
  }

  fetchRestData(options)
    .then(json => {
      const gibs = json.data
      // eslint-disable-next-line camelcase
      const enrichmentUrl = gibs?.settings?.external_url
      if (enrichmentUrl) {
        window.open(enrichmentUrl, '_blank')
      }
    })
    .catch(e => {
      // eslint-disable-next-line no-console
      console.error(e)
    })
}

/**
 * Fired after the close function on enrichment drawer has been executed
 * - Hide enrichment drawer
 * - Remove active enrichment
 * - If available, load the enrichment waiting to be opened
 */
const onEnrichmentClose = ({ getState, dispatch }) => {
  dispatch(closePanel(PANEL_PRODUCT_DRAWER))
  dispatch(removeActiveEnrichment())
  const state = getState()
  const nextEnrichment = selectNextEnrichment(state)
  if (nextEnrichment) dispatch(onEnrichmentClickAction(nextEnrichment))
}

export default [
  takeEvery(ON_ENRICHMENT_CLICK, onEnrichmentClick),
  takeEvery(SET_ACTIVE_ENRICHMENT, onOpenEnrichment),
  takeEvery(OPEN_EXTERNAL_ENRICHMENT, onOpenExternalEnrichment),
  takeEvery(ON_ENRICHMENT_CLOSE, onEnrichmentClose),
]
