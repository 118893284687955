export const SET_RETAILER = 'RETAILER/SET_RETAILER'
export const SET_RETAILERS = 'RETAILERS/SET_RETAILERS'
export const SET_RETAILER_ASSETS = 'RETAILERS/SET_RETAILER_ASSETS'

export const setRetailer = retailer => ({
  type: SET_RETAILER,
  payload: { retailer },
})

export const setRetailers = retailers => ({
  type: SET_RETAILERS,
  payload: { retailers },
})

export const setRetailerAssets = jsonData => ({
  type: SET_RETAILER_ASSETS,
  payload: {
    assets: jsonData,
  },
})
