import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import styled from '@emotion/styled'
import uuidv1 from 'uuid/v1'
import debounce from '@doveconviene/sf-web-js-sdk/debounce'
import { env, webStorage } from './utils'

import { setClientViewport, setUserId } from './redux/actions'

import { getBreakpoint } from './helpers/breakpoints'

const StyledRoot = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  overflow: 'hidden',
})

const App = ({ children }) => {
  const dispatch = useDispatch()

  const getViewportData = () => ({
    width: window.innerWidth,
    height: window.innerHeight,
    breakpoint: getBreakpoint(window.innerWidth),
  })

  const onWindowResize = debounce(() => {
    dispatch(setClientViewport(getViewportData()))
  }, 250)

  useEffect(() => {
    document.documentElement.setAttribute('lang', env('REACT_APP_ONE_TRUST_LANG'))

    dispatch(setClientViewport(getViewportData()))
    window.addEventListener('resize', onWindowResize)

    let id = webStorage.getItem('userId')
    if (!id) {
      id = uuidv1()
      webStorage.setItem('userId', id)
    }
    dispatch(setUserId(id))

    return () => {
      window.removeEventListener('resize', onWindowResize)
    }
  }, [dispatch, onWindowResize])

  return (
    <StyledRoot>
      {children}
    </StyledRoot>
  )
}

App.propTypes = {
  children: PropTypes.element,
}

App.defaultProps = {
  children: null,
}

export default App
