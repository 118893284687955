import { env } from '.'

export const OT_TARGETING_ADS_PREFERENCE = 'C0004'
export const OT_ALL_PREFERENCES = ['C0004', 'C0002', 'C0001', 'V2STACK42']

export const DISCLAIMER_LINK = `https://${env('REACT_APP_BASE_URL')}/${env('REACT_APP_PRIVACY_SLUG')}`

// Table of Contents
export const TABLE_OF_CONTENTS_TOOLTIP_KEY = 'toc-acknowledged'

export const ESCAPE_KEY = 27

export const DRAWER_MODE_PREVIEW = 'preview'
export const DRAWER_MODE_EXPANDED = 'expanded'
export const DRAWER_ACTIONS = {
  preview: 'open_preview',
  expanded: 'open_expanded',
  expanded_swipe: 'swipe_up',
  expanded_cta: 'click_cta_expand',
  preview_swipe: 'swipe_down',
  preview_cta: 'click_cta_reduce',
  close: 'close',
}
