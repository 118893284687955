import queryString from 'query-string'

import {
  PANEL_CROSSELL,
  PANEL_SMARTBANNER,
  LAYOUT_DELAYED_OPENPANEL,
  LAYOUT_OPENPANEL,
  VIEWER_INITIALIZED,
  VIEWER_BROWSE,
  VIEWER_LAST_PAGE,
  VIEWER_SWIPE_LAST,
  VIEWER_EXIT,
  delayedOpenPanel,
  openPanel,
  closePanel,
  openFullscreen,
  closeFullscreen,
} from '../../actions'

import {
  selectLayoutActivePanels,
  selectRouterLocation,
} from '../../selectors'

import { takeEvery } from '../helpers'

import { env } from '../../../utils'

const handleDelayedPanels = () => {
  const timeouts = {}

  return ({ dispatch }) => next => action => {
    next(action)

    if (action.type === LAYOUT_DELAYED_OPENPANEL) {
      const { panel, timeout } = action.payload

      clearTimeout(timeouts[panel])

      timeouts[panel] = setTimeout(() => {
        dispatch(openPanel(panel))
      }, timeout)
    }

    if (action.type === LAYOUT_OPENPANEL) {
      const { panel } = action.payload

      clearTimeout(timeouts[panel])
    }
  }
}

const watchForSmartBannerOpen = () => {
  let alreadyOpened = false

  return () => next => action => {
    if (action.type === LAYOUT_OPENPANEL) {
      const { panel } = action.payload

      if (panel === PANEL_SMARTBANNER) {
        if (alreadyOpened) return

        alreadyOpened = true
      }
    }

    next(action)
  }
}

const watchForCrossellOpen = ({ getState }) => next => action => {
  if (action.type === LAYOUT_OPENPANEL) {
    const state = getState()
    const { panel } = action.payload
    const { hideCrossell } = queryString.parse(selectRouterLocation(state).search)

    if (panel === PANEL_CROSSELL && hideCrossell === 'true') return

    next(action)
  } else {
    next(action)
  }
}

const onViewerInitialize = ({ getState, dispatch }, action) => {
  const state = getState()

  const { client, layout } = state
  const { currentPages } = action.payload.viewerState
  let { appDownload } = queryString.parse(selectRouterLocation(state).search)

  appDownload = (appDownload === 'auto') ? env('REACT_APP_SMART_BANNER_DEFAULT_AUTO') : appDownload
  const openSmartBanner = Math.max(...currentPages) >= appDownload || appDownload === 'true'

  setTimeout(() => {
    if (!layout.fullscreen) {
      dispatch(openFullscreen())
    }
  }, 5000)

  if (client.mobile && openSmartBanner) {
    dispatch(openPanel(PANEL_SMARTBANNER))
  }
}

const onViewerBrowse = ({ getState, dispatch }, action) => {
  const state = getState()

  const { client } = state
  let { appDownload } = queryString.parse(selectRouterLocation(state).search)
  appDownload = (appDownload === 'auto') ? env('REACT_APP_SMART_BANNER_DEFAULT_AUTO') : appDownload

  const { currentPages } = action.payload.pagesData
  const openSmartBanner = Math.max(...currentPages) >= appDownload

  // eslint-disable-next-line no-bitwise
  if (client.mobile && openSmartBanner) dispatch(openPanel(PANEL_SMARTBANNER))
}

const onViewerLastPage = ({ getState, dispatch }) => {
  const state = getState()

  const { client, viewer } = state

  let { appDownload } = queryString.parse(selectRouterLocation(state).search)
  appDownload = (appDownload === 'auto') ? env('REACT_APP_SMART_BANNER_DEFAULT_AUTO') : appDownload
  appDownload = appDownload >= viewer.numberOfPages ? 'last' : appDownload

  if (client.mobile) {
    if (appDownload === 'last') {
      dispatch(openPanel(PANEL_SMARTBANNER))
    }
    dispatch(delayedOpenPanel(PANEL_CROSSELL, env('REACT_APP_CROSSELL_TIMEOUT_TRIGGER')))
  }
}

const onViewerSwipeLast = ({ getState, dispatch }) => {
  const state = getState()

  const { client } = state

  if (client.mobile) {
    dispatch(openPanel(PANEL_CROSSELL))
  }
}

const onViewerExit = ({ getState, dispatch }) => {
  const state = getState()

  const activePanels = selectLayoutActivePanels(state)

  activePanels.forEach(panel => dispatch(closePanel(panel)))

  dispatch(closeFullscreen())
}

export default [
  handleDelayedPanels(),
  watchForSmartBannerOpen(),
  watchForCrossellOpen,
  takeEvery(VIEWER_INITIALIZED, onViewerInitialize),
  takeEvery(VIEWER_BROWSE, onViewerBrowse),
  takeEvery(VIEWER_LAST_PAGE, onViewerLastPage),
  takeEvery(VIEWER_SWIPE_LAST, onViewerSwipeLast),
  takeEvery(VIEWER_EXIT, onViewerExit),
]
