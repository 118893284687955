export const SET_STORES = 'STORES/SET_STORES'
export const STORES_PICKSTORES = 'STORES/PICKSTORES'

export const setStores = stores => ({
  type: SET_STORES,
  payload: {
    stores,
  },
})

export const pickStores = stores => ({
  type: STORES_PICKSTORES,
  payload: { stores },
})
