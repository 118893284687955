import {
  VIEWER_STATE_RECEIVE,
  VIEWER_INITIALIZED,
  VIEWER_BROWSE,
  VIEWER_ZOOM,
  SET_VIEWER_FLYER_ID,
  SET_VIEWER_FLYER_PAGE,

  // Table of Contents
  TABLE_OF_CONTENTS_INITIALIZED,
  SET_TABLE_OF_CONTENTS_TOOLTIP_VISIBILITY,
} from '../actions'
import initialState from '../state/viewer.state'

export default function (state = initialState, action) {
  switch (action.type) {
    case VIEWER_BROWSE: {
      const { slide, currentPages, isLast } = action.payload.pagesData
      return {
        ...state,
        slide,
        currentPages,
        isLast,
      }
    }

    case VIEWER_ZOOM: {
      const { rate } = action.payload
      return {
        ...state,
        zoom: rate,
      }
    }

    case SET_VIEWER_FLYER_ID: {
      const { flyerId } = action.payload
      return { ...state, flyerId }
    }

    case SET_VIEWER_FLYER_PAGE: {
      const { flyerPage } = action.payload
      return { ...state, flyerPage }
    }

    case VIEWER_INITIALIZED:
    case VIEWER_STATE_RECEIVE: {
      const { viewerState } = action.payload
      return {
        ...state,
        ...viewerState,
      }
    }

    case TABLE_OF_CONTENTS_INITIALIZED: {
      const { viewerHasTableOfContents } = action.payload
      return {
        ...state,
        viewerHasTableOfContents,
      }
    }

    case SET_TABLE_OF_CONTENTS_TOOLTIP_VISIBILITY: {
      const { isTableOfContentsTooltipEnabled } = action.payload
      return {
        ...state,
        isTableOfContentsTooltipEnabled,
      }
    }

    default:
      return state
  }
}
