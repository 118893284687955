import {
  SET_ACTIVE_ENRICHMENT,
  SET_NEXT_ENRICHMENT,
  REMOVE_ACTIVE_ENRICHMENT,
} from '../actions'
import initialState from '../state/enrichment.state'

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_ENRICHMENT: {
      const { enrichment } = action.payload
      return { ...state, activeEnrichment: enrichment, nextEnrichment: null }
    }

    case SET_NEXT_ENRICHMENT: {
      const { enrichment } = action.payload
      return { ...state, nextEnrichment: enrichment }
    }

    case REMOVE_ACTIVE_ENRICHMENT: {
      return { ...state, activeEnrichment: null }
    }

    default:
      return state
  }
}
