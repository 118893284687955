import { compile } from 'path-to-regexp'
import { replace } from 'connected-react-router'

import debounce from '@doveconviene/sf-web-js-sdk/debounce'

import {
  VIEWER_BROWSE,
} from '../../actions'

import {
  selectRouterLocation,
  selectViewerFlyerId,
} from '../../selectors'

import { takeEvery } from '../helpers'

import { flyerPath, flyerPathV2 } from '../../../routes'

const onViewerBrowse = ({ getState, dispatch }, { payload }) => {
  const state = getState()

  const { search, pathname } = selectRouterLocation(state)

  const flyerId = selectViewerFlyerId(state)
  const { currentPages: [flyerPage] } = payload.pagesData

  const pathTo = compile(pathname.includes('v2') ? flyerPathV2 : flyerPath)

  dispatch(replace({
    pathname: pathTo({ flyerId, flyerPage }),
    search,
  }))
}
export default [
  takeEvery(VIEWER_BROWSE, debounce(onViewerBrowse, 1000)),
]
