import queryString from 'query-string'

export default queryParams => {
  const search = queryParams || window.location.search
  let queryStringParams = queryString.parse(search, { ignoreQueryPrefix: true })
  const { epoi = null } = queryStringParams
  if (epoi) {
    try {
      const qsToParse = atob(epoi)
      const splittedParams = qsToParse.split('|')
      if (splittedParams.length > 1) {
        const lat = splittedParams[0]
        const lng = splittedParams[1]
        queryStringParams = { ...queryStringParams, lat, lng }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Unable to parse epoi from queryStrig, falling back to default')
    }
  }
  return queryStringParams
}
