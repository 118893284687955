/* eslint-disable no-underscore-dangle */
import queryString from 'query-string'
import {
  ONETRUST_INIT,
  setUserCookies,
  oneTrustActivationDone,
  oneTrustOnReady,
} from '../../actions'
import { selectRouterLocation } from '../../selectors'
import {
  env, getCookiesConsent, isPreferenceActive,
} from '../../../utils'
import { OT_TARGETING_ADS_PREFERENCE } from '../../../utils/consts'

let consentUpdated = false

// Update the existing consent
const updateConsent = adStorage => {
  window.gtag('consent', 'update', {
    analytics_storage: 'granted',

    // ADS "C0004" (Targeted advertising)
    ad_storage: adStorage ? 'granted' : 'denied',
    ad_user_data: adStorage ? 'granted' : 'denied',
    ad_personalization: adStorage ? 'granted' : 'denied',

    functionality_storage: 'denied',
    security_storage: 'denied',
    personalization_storage: 'denied',
  })
  consentUpdated = true
}

const initializeOneTrust = ({ getState, dispatch }) => next => action => {
  next(action)
  if (action.type !== ONETRUST_INIT) return

  const state = getState()
  const { preview } = queryString.parse(selectRouterLocation(state).search)
  const isPreview = preview === 'true'

  if (isPreview) {
    dispatch(setUserCookies(false))
    return
  }

  // https://developer.onetrust.com/onetrust/docs/supported-languages-and-html-language-codes
  // Not all HTML language codes listed here are supported.
  // Some of them (example es-co, pt-br) are giving this error:
  // "Language:xxx doesn't exist for the geo rule"
  // For this reason I'm using the main lang (es) instead of specific by country (es-co)
  const lang = env('REACT_APP_ONE_TRUST_LANG')
  const domain = env('REACT_APP_ONE_TRUST_DOMAIN')

  window.OptanonWrapper = function () {
    window.OneTrust.changeLanguage(lang)
    window.__tcfapi('addEventListener', 2, (tcData, success) => {
      // considering that OptanonWrapper is run every time an action to cookie consent is triggered,
      // we need to remove the listener every time the callback is called
      window.__tcfapi('removeEventListener', 2, () => {}, tcData.listenerId)

      if (!success) return

      window.tcData = tcData

      // OT is loaded for the first time (consent not given yet)
      if (tcData.cmpStatus === 'loaded' && tcData.eventStatus === 'cmpuishown') {
        dispatch(oneTrustOnReady())
        if (!consentUpdated) {
          // Consent is needed and the user has not expressed his preference yet
          updateConsent(false)
        }
      }

      // OT is loaded NOT for the first time (consent accepted/rejected)
      if (tcData.eventStatus === 'tcloaded') {
        updateConsent(isPreferenceActive(OT_TARGETING_ADS_PREFERENCE))
        dispatch(setUserCookies(getCookiesConsent()))
        dispatch(oneTrustOnReady())
        dispatch(oneTrustActivationDone())
      }

      // user completed a cookie action (preference changed) -> onPreferenceExpressed
      if (tcData.cmpStatus === 'loaded' && tcData.eventStatus === 'useractioncomplete') {
        updateConsent(isPreferenceActive(OT_TARGETING_ADS_PREFERENCE))
        dispatch(setUserCookies(getCookiesConsent()))
        dispatch(oneTrustOnReady())
        dispatch(oneTrustActivationDone())
      }
    })
  }

  const oneTrustStubLibraryScript = document.createElement('script')
  oneTrustStubLibraryScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  oneTrustStubLibraryScript.type = 'text/javascript'
  oneTrustStubLibraryScript.charset = 'UTF-8'
  oneTrustStubLibraryScript.setAttribute('data-domain-script', domain)
  // Use the html lang as default language for OneTrust
  oneTrustStubLibraryScript.setAttribute('data-document-language', true)

  document.getElementsByTagName('body')[0].appendChild(oneTrustStubLibraryScript)
}

export default [
  initializeOneTrust,
]
