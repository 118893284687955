import { SET_CATEGORIES, SET_CATEGORY } from '../actions'
import initialState from '../state/categories.state'

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORIES: {
      const { categories } = action.payload

      return {
        ...state,
        list: {
          ...state.list,
          ...categories,
        },
      }
    }
    case SET_CATEGORY: {
      const { category } = action.payload
      return {
        ...state,
        list: {
          ...state.list,
          [category.id]: category,
        },
      }
    }

    default:
      return state
  }
}
