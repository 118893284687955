import { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'

import { history } from '../redux'
import { RedirectWithParams } from '../shared/components'

import lazyfy from '../utils/lazify'
import Loading from '../components/loading'
import { NotFound } from '../components/notFound'

const Flyer = lazyfy(() => import('./flyer.route'))
const FlyerV2 = lazyfy(() => import('./flyerv2.route'))
const LandingGeo = lazyfy(() => import('./landingGeo.route'))
export const flyerPath = '/flyer/:flyerId/:flyerPage'
export const landingGeoPath = '/landingGeo/:flyerId'
export const flyerPathV2 = '/v2/flyer/:flyerId/:flyerPage'
export const notFoundPath = '/404'

const { location: { pathname, search } } = history

export default (
  <Suspense fallback={(<Loading />)}>
    <Router history={history}>
      <Switch>
        <Route exact path={flyerPath} component={Flyer} />
        <Route exact path={landingGeoPath} component={LandingGeo} />
        <RedirectWithParams exact from="/flyer/:flyerId" to="/flyer/:flyerId/1" preserveSearch />
        <Route exact path={flyerPathV2} component={FlyerV2} />
        <RedirectWithParams exact from="/v2/flyer/:flyerId" to="/v2/flyer/:flyerId/1" preserveSearch />
        <Route exact path={notFoundPath} component={NotFound} />

        <Redirect to={{
          pathname: notFoundPath,
          search: `?referrer=${pathname}${search}`,
        }}
        />
      </Switch>
    </Router>
  </Suspense>
)
