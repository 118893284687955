import { pathToRegexp } from 'path-to-regexp'
import queryString from 'query-string'
import {
  gtag,
  APPLICATION_INIT,
  setIsPreview,
  setViewerFlyerId,
  setViewerFlyerPage,
  setTableOfContentsTooltipVisibility,
} from '../../actions'
import { appendGtagLibraryScript, webStorage } from '../../../utils'

import {
  selectRouterLocation,
  selectTrackingGAWArbitrage,
  selectTrackingGAWRemarketing,
  selectTrackingGAWPagesPercentageAt,
  selectTrackingGAnalytics,
  selectUserCookiesPreference,
} from '../../selectors'
import { OT_TARGETING_ADS_PREFERENCE, TABLE_OF_CONTENTS_TOOLTIP_KEY } from '../../../utils/consts'
import { getCategoriesMenu } from '../api/categories'
import { getFlyer } from '../api/flyers'

const initializePreview = ({ getState, dispatch }) => next => action => {
  next(action)
  if (action.type === APPLICATION_INIT) {
    const state = getState()

    // enable view for inactive flyers ?isPreview={true|false}
    const { preview } = queryString.parse(selectRouterLocation(state).search)
    const isPreview = preview === 'true'
    dispatch(setIsPreview(isPreview))
  }
}

const initializePixels = ({ getState, dispatch }) => next => action => {
  if (action.type === APPLICATION_INIT) {
    const state = getState()

    // GTAG pixels
    const gAWArbitragePixels = selectTrackingGAWArbitrage(state)
    const gAWRemarketingPixels = selectTrackingGAWRemarketing(state)
    const gAWPercentagePixels = [
      selectTrackingGAWPagesPercentageAt(30)(state),
      selectTrackingGAWPagesPercentageAt(50)(state),
      selectTrackingGAWPagesPercentageAt(80)(state),
    ]

    // Google Analytics
    const gAnalyticsIds = selectTrackingGAnalytics(state)

    const GAWids = [
      ...gAWArbitragePixels,
      ...gAWRemarketingPixels,
      ...gAWPercentagePixels,
    ].map(pixel => pixel.id)

    appendGtagLibraryScript(GAWids[0])
      .then(() => {
        dispatch(gtag('js', new Date()))
        GAWids.forEach(id => dispatch(gtag('config', id, {
          send_page_view: false,
          cookie_flags: 'secure;samesite=none',
        })))

        const isConsentGiven = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)
        const { location: { pathname, search } } = state.router

        // Google Analytics
        gAnalyticsIds.forEach(id => {
          dispatch(gtag('config', id, {
            anonymize_ip: true,
            custom_map: { dimension1: 'CookieAcceptance' },
            send_page_view: false,
            cookie_flags: 'secure;samesite=none',
          }))

          dispatch(gtag('event', 'page_view', {
            send_to: id,
            page_path: `${pathname}${search}`,
            CookieAcceptance: `${isConsentGiven}`,
          }))
        })
      })
      .catch(console.error) // eslint-disable-line no-console
  }

  next(action)
}

const initializeApp = ({ dispatch }) => next => action => {
  next(action)
  if (action.type === APPLICATION_INIT) {
    const parsedPathnameV1 = pathToRegexp('/flyer/:flyerId/:flyerPage?').exec(window.location.pathname)
    const parsedPathnameV2 = pathToRegexp('/v2/flyer/:flyerId/:flyerPage?').exec(window.location.pathname)
    const parsedPathnameLandingGeo = pathToRegexp('/landingGeo/:flyerId?').exec(window.location.pathname)
    const flyerPatch = parsedPathnameV1 || parsedPathnameV2 || parsedPathnameLandingGeo

    if (flyerPatch) {
      const [, flyerId, flyerPage = 1] = flyerPatch

      const flyerIdNumber = Number.parseInt(flyerId, 10)
      const flyerPageNumber = Number.parseInt(flyerPage, 10)
      const isTooltipAcknowledged = webStorage.getItem(TABLE_OF_CONTENTS_TOOLTIP_KEY)

      dispatch(setTableOfContentsTooltipVisibility(Boolean(isTooltipAcknowledged) !== true))
      dispatch(setViewerFlyerId(flyerIdNumber))
      dispatch(setViewerFlyerPage(flyerPageNumber))
      dispatch(getCategoriesMenu())
      dispatch(getFlyer(flyerId))
    }
  }
}

export default [
  initializePixels,
  initializePreview,
  initializeApp,
]
