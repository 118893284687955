import { OT_ALL_PREFERENCES } from './consts'

const getActivePreferences = () => window.OnetrustActiveGroups && window.OnetrustActiveGroups.split(',').filter(group => group)

export const isPreferenceActive = cookie => window.OnetrustActiveGroups.indexOf(cookie) >= 0

// isConsentGiven => all cookies accepted
const isConsentGiven = () => (
  window.OnetrustActiveGroups
  && OT_ALL_PREFERENCES.every(cookie => window.OnetrustActiveGroups.indexOf(cookie) >= 0)
)

// isPreferenceExpressed => users accept/reject cookies
const isPreferenceExpressed = () => (
  window.tcData
  && window.tcData.cmpStatus === 'loaded'
  && (window.tcData.eventStatus === 'useractioncomplete' || window.tcData.eventStatus === 'tcloaded')
)

export const openPreferences = () => window.OneTrust && window.OneTrust.ToggleInfoDisplay()

const getCookiesConsent = () => {
  let cookies = {
    isConsentGiven: false,
    isPreferenceExpressed: false,
    preferences: [],
  }

  if (window.tcData && window.OnetrustActiveGroups) {
    cookies = {
      isConsentGiven: isConsentGiven(),
      isPreferenceExpressed: isPreferenceExpressed(),
      preferences: getActivePreferences(),
    }
  }

  return cookies
}

export default getCookiesConsent
