export const APPLICATION_INIT = 'APPLICATION/INIT'
export const ONETRUST_INIT = 'APPLICATION/ONETRUST_INIT'

export const oneTrustInit = () => ({
  type: ONETRUST_INIT,
  payload: {},
})

export const applicationInit = () => ({
  type: APPLICATION_INIT,
  payload: {},
})
