import {
  gtag, OPEN_EXTERNAL_ENRICHMENT, ON_ENRICHMENT_CTA_CLICK, VIEWER_INITIALIZED,
} from '../../../actions'
import {
  selectTrackingGAWArbitrage,
  selectTrackingGAWRemarketing,
  selectTrackingGAWExtn,
  selectTrackingGAWPagesPercentageAt,
  selectViewerFlyerId,
  selectFlyerData,
  selectUserCookiesPreference,
} from '../../../selectors'

import { afterViewerBrowsePercentagePages } from './helpers'
import { OT_TARGETING_ADS_PREFERENCE } from '../../../../utils/consts'
import { env } from '../../../../utils'

export const onViewerInitialized = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

  if (action.type === VIEWER_INITIALIZED && isPreferenceExpressed) {
    const gAWArbitragePixels = selectTrackingGAWArbitrage(state)
    const gAWRemarketingPixels = selectTrackingGAWRemarketing(state)
    const gAWExtnPixels = selectTrackingGAWExtn(state)
    const flyerId = selectViewerFlyerId(state)
    const country = env('REACT_APP_COUNTRY_ISO3166_1').toLowerCase()

    gAWArbitragePixels.forEach(({ id, label }) => {
      dispatch(gtag('event', 'conversion', {
        send_to: `${id}/${label}`,
      }))
    })
    gAWRemarketingPixels.forEach(({ id }) => {
      dispatch(gtag('event', 'conversion', {
        send_to: id,
        // https://support.google.com/tagmanager/answer/6106960?hl=it
        aw_remarketing_only: true,
      }))
    })
    gAWExtnPixels.forEach(({ id }) => {
      dispatch(gtag('event', 'EnFlyerOpen', {
        send_to: `${id}`,
        en_flyer_id: `${flyerId}`,
        country,
        source: 'webslim',
      }))
    })
  }
}

/**
 * The ON_ENRICHMENT_CTA_CLICK action is fired on click on the product panel LOAD MORE cta
 * (which means, the payload.enrichment must be a valid object anyway)
 */
const onViewerEnrichmentCtaClick = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

  if (action.type === ON_ENRICHMENT_CTA_CLICK && isPreferenceExpressed) {
    const { flyer_id: flyerId, settings: { flyer_page: flyerPage } } = action.payload.enrichment
    const gAWArbitragePixels = selectTrackingGAWArbitrage(state)
    const flyer = selectFlyerData(flyerId)(state)

    const externalLinkOptions = {
      flyer_page: flyerPage,
      retailer_slug: flyer.retailer.slug,
      retailer_category: flyer.retailer.category.slug,
    }

    gAWArbitragePixels.forEach(({ id, label }) => {
      dispatch(gtag('event', 'ExternalLinkClicked', {
        send_to: `${id}/${label}`,
        ...externalLinkOptions,
      }))
    })
  }
}

/**
 * On click on an external enrichment (type === external_link).
 * The OPEN_EXTERNAL_ENRICHMENT payload must always contain a valid enrichment
 */
const onViewerEnrichmentClick = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

  if (
    action.type === OPEN_EXTERNAL_ENRICHMENT
    && isPreferenceExpressed
  ) {
    const { flyerPage } = action.payload.enrichment
    const flyerId = selectViewerFlyerId(state)
    const flyer = selectFlyerData(flyerId)(state)

    const gAWArbitragePixels = selectTrackingGAWArbitrage(state)

    gAWArbitragePixels.forEach(({ id, label }) => {
      dispatch(gtag('event', 'ExternalLinkClicked', {
        send_to: `${id}/${label}`,
        flyer_page: flyerPage,
        retailer_slug: flyer.retailer.slug,
        retailer_category: flyer.retailer.category.slug,
      }))
    })
  }
}

const coversionAfterPercentagePages = goalPercentage => (
  afterViewerBrowsePercentagePages(goalPercentage, ({ getState, dispatch }) => {
    const state = getState()
    const { id, label } = selectTrackingGAWPagesPercentageAt(goalPercentage)(state)
    dispatch(gtag('event', 'conversion', {
      send_to: `${id}/${label}`,
    }))
  })
)

export default [
  onViewerInitialized,
  onViewerEnrichmentClick,
  onViewerEnrichmentCtaClick,
  coversionAfterPercentagePages(30),
  coversionAfterPercentagePages(50),
  coversionAfterPercentagePages(80),
]
