import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import isAfter from 'date-fns/isAfter'
import addDays from 'date-fns/addDays'
import getHours from 'date-fns/getHours'

import it from 'date-fns/locale/it'
import enAU from 'date-fns/locale/en-AU'
import enNZ from 'date-fns/locale/en-NZ'
import pt from 'date-fns/locale/pt'
import es from 'date-fns/locale/es'

import { env } from '.'

const getDateFnsLocaleObject = localeString => {
  switch (localeString) {
    case 'it':
      return it
    case 'en-AU':
      return enAU
    case 'en-NZ':
      return enNZ
    case 'pt':
      return pt
    case 'es':
      return es
    default:
      return enAU
  }
}

const localeString = env('REACT_APP_LOCALE')
const locale = getDateFnsLocaleObject(localeString)

/**
 * Returns expiration text from a date
 */
const getExpirationText = endDate => {
  const newEndDate = new Date(endDate)
  const hours = getHours(newEndDate)
  // if hours is 0, add 1 day
  const expirationDate = hours ? newEndDate : addDays(newEndDate, 1)
  const expiresOrExpired = isAfter(expirationDate, new Date())
    ? env('REACT_APP_STRING_EXPIRES')
    : env('REACT_APP_STRING_EXPIRED')
  const distance = formatDistanceToNow(expirationDate, {
    addSuffix: true, locale,
  })

  return `${expiresOrExpired} ${distance}`
}

export default getExpirationText
