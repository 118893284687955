import { Provider } from 'react-redux'
import UIWrapper from '@doveconviene/react-sf-ui/components/UIWrapper'

import Routes from './routes'
import App from './app.container'
import configureStore from './redux'
import { applicationInit, oneTrustInit } from './redux/actions'
import theme from './components/Layout/theme'

const store = configureStore()

store.dispatch(oneTrustInit())
store.dispatch(applicationInit())

export default (
  <Provider store={store}>
    <UIWrapper theme={theme}>
      <App>
        {Routes}
      </App>
    </UIWrapper>
  </Provider>
)
