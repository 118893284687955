export const SET_CATEGORIES = 'CATEGORIES/SET_CATEGORIES'
export const SET_CATEGORY = 'CATEGORY/SET_CATEGORY'

/**
 * @param {Object} categories
 */
export const setCategories = categories => ({
  type: SET_CATEGORIES,
  payload: { categories },
})

/**
 * @param {boolean} category
 */
export const setCategory = category => ({
  type: SET_CATEGORY,
  payload: {
    category,
  },
})
