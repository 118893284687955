import { request } from '../../../actions/fetch.actions'
import {
  selectUserGeolocation, selectRelatedFlyersIds, selectFlyer,
} from '../../../selectors'
import { normalizeFlyerData, normalizeFlyersData } from './sanitizer'
import { setRelatedFlyers, setFlyers, setFlyer } from '../../../actions/flyers.actions'
import { setCategory } from '../../../actions/categories.actions'
import { receiveCategoriesData } from '../categories'
import { receiveRetailerData, receiveRetailersData } from '../retailers'
import { sanitizeId } from '../../../../utils'

export function flyersRequestId(id, type = 'flyers') {
  return `${type}/${id}`
}

export function fetchFlyerParams(flyerId) {
  return {
    resource: `flyers/${flyerId}`,
    paginate: false,
    params: {
      conditions: {
        is_active: 1,
      },
      fields: 'id,title,end_date,unpublish_at,publication_url,retailer_id,settings,is_active,is_premium',
    },
    populate: flyer => ({
      retailer: {
        resource: `retailers/${flyer.retailer_id}`,
        params: {
          fields: 'id,name,slug,category_id,settings',
        },
        populate: retailer => ({
          category: {
            resource: `categories/${retailer.category_id}`,
            params: {
              fields: 'id,name,slug,priority,is_home,is_highlight',
            },
            populate: category => ({
              assets: {
                resource: `categories/${category.id}/assets`,
                params: {
                  conditions: {
                    name: 'svg',
                  },
                },
              },
            }),
          },
        }),
      },
    }),
  }
}

export function fetchRelatedFlyersParams(flyerId, geolocation) {
  const { lat, lng } = geolocation

  return {
    resource: `flyers/${flyerId}`,
    params: {
      conditions: {
        is_active: 1,
      },
      fields: 'id',
    },
    populate: flyer => ({
      related: {
        resource: `flyers/${flyer.id}/flyers`,
        paginate: false,
        params: {
          ll: `${lat},${lng}`,
          modifiers: 'deduplication',
          conditions: {
            is_active: 1,
          },
        },
        populate: relatedFlyer => ({
          retailer: {
            resource: `retailers/${relatedFlyer.retailer_id}`,
            params: {
              fields: 'id,name,slug,category_id,settings',
            },
            populate: retailer => ({
              category: {
                resource: `categories/${retailer.category_id}`,
                params: {
                  fields: 'id,name,slug,priority,is_home,is_highlight',
                },
                populate: category => ({
                  assets: {
                    resource: `categories/${category.id}/assets`,
                    params: {
                      conditions: {
                        name: 'svg',
                      },
                    },
                  },
                }),
              },
            }),
          },
        }),
      },
    }),
  }
}

/**
 * Dispatched when a single successfull flyer fetch completes
 */
export function receiveFlyerData(jsonData) {
  const { retailer } = jsonData
  const flyer = normalizeFlyerData(jsonData)
  const { category } = jsonData.retailer

  return dispatch => {
    dispatch(setFlyer(flyer))
    dispatch(receiveRetailerData({ retailer }))
    dispatch(setCategory(category))
  }
}

/**
 * Fetches a single flyer avoiding fetching categories
 * which is for now the only way we have to make a distinction between categories and single fetch
 */
export function fetchFlyer(flyerId) {
  return dispatch => {
    const rId = `flyers/${flyerId}`
    const params = fetchFlyerParams(flyerId)

    return dispatch(request(rId, params, receiveFlyerData))
  }
}

/**
 * Dispatched when a group of flyers has been fetched successfully
 */
export function receiveFlyersData(jsonData) {
  const flyers = normalizeFlyersData(jsonData.flyers)
  const retailers = jsonData.flyers.map(flyer => flyer.retailer)
  const categories = jsonData.flyers.map(flyer => flyer.retailer.category)

  return dispatch => {
    dispatch(setFlyers(flyers))
    dispatch(receiveRetailersData(retailers))
    dispatch(receiveCategoriesData(categories))
  }
}

/**
 * Dispatched when a request to related flyers successfully completes
 */
export function receiveRelatedFlyersData(jsonData) {
  const { id, related } = normalizeFlyerData(jsonData)
  const relatedFlyerIds = related.map(relatedFlyer => relatedFlyer.id)
  return dispatch => {
    dispatch(setRelatedFlyers(id, relatedFlyerIds))
    dispatch(receiveFlyersData({ flyers: related }))
  }
}

/**
 * Checks to see if data has already been fetched
 * if not launches fetch
 */
export function fetchRelatedFlyers(flyerId) {
  return (dispatch, getState) => {
    const state = getState()

    const relatedFlyers = selectRelatedFlyersIds(flyerId)(state)

    if (!relatedFlyers) {
      const geolocation = selectUserGeolocation(state)

      const rId = `relatedFlyers/${flyerId}`
      const params = fetchRelatedFlyersParams(flyerId, geolocation)

      return dispatch(request(rId, params, receiveRelatedFlyersData))
    }

    return false
  }
}

/**
 * Checks to see if the flyer has already been fetched or a request is currently registered
 * if not launches fetch
 */
export function getFlyer(id) {
  const flyerId = sanitizeId(id)

  return (dispatch, getState) => {
    const state = getState()
    const flyer = selectFlyer(flyerId)(state)

    if (!flyer) {
      dispatch(fetchFlyer(flyerId))
    }
  }
}

export function getRelatedFlyers(id) {
  const flyerId = sanitizeId(id)
  return dispatch => dispatch(fetchRelatedFlyers(flyerId))
}
