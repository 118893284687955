import { SET_STORES, STORES_PICKSTORES } from '../actions'
import initialState from '../state/stores.state'

export default function (state = initialState, action) {
  const { list } = state

  switch (action.type) {
    case STORES_PICKSTORES: {
      const { stores } = action.payload
      return { ...state, picks: stores }
    }

    case SET_STORES: {
      const { stores } = action.payload
      const newList = stores.reduce(
        (storesAcc, store) => ({ ...storesAcc, [store.id]: { ...store } }),
        list,
      )
      return { ...state, list: newList }
    }

    default:
      return state
  }
}
