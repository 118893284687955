export const PANEL_STORES = 'LAYOUT/PANEL/STORES'
export const PANEL_CROSSELL = 'LAYOUT/PANEL/CROSSELL'
export const PANEL_APP_DOWNLOAD = 'LAYOUT/PANEL/APP_DOWNLOAD'
export const PANEL_SMARTBANNER = 'LAYOUT/PANEL/SMARTBANNER'
export const PANEL_COOKIES = 'LAYOUT/PANEL/COOKIES'
export const PANEL_PRODUCT_DRAWER = 'LAYOUT/PANEL/PRODUCT_DRAWER'

export const LAYOUT_OPENFULLSCREEN = 'LAYOUT/OPENFULLSCREEN'
export const LAYOUT_CLOSEFULLSCREEN = 'LAYOUT/CLOSEFULLSCREEN'
export const LAYOUT_TOGGLEFULLSCREEN = 'LAYOUT/TOGGLEFULLSCREEN'

export const LAYOUT_OPENPANEL = 'LAYOUT/OPENPANEL'
export const LAYOUT_CLOSEPANEL = 'LAYOUT/CLOSEPANEL'
export const LAYOUT_DELAYED_OPENPANEL = 'LAYOUT/DELAYED_OPENPANEL'

export const SET_IS_PREVIEW = 'LAYOUT/SET_IS_PREVIEW'

export const SET_NEXT_FLYER_OPEN = 'LAYOUT/SET_NEXT_FLYER_OPEN'

export const openFullscreen = () => ({
  type: LAYOUT_OPENFULLSCREEN,
  payload: {},
})

export const closeFullscreen = () => ({
  type: LAYOUT_CLOSEFULLSCREEN,
  payload: {},
})

export const toggleFullscreen = () => ({
  type: LAYOUT_TOGGLEFULLSCREEN,
  payload: {},
})

export const openPanel = panel => ({
  type: LAYOUT_OPENPANEL,
  payload: { panel },
})

export const closePanel = panel => ({
  type: LAYOUT_CLOSEPANEL,
  payload: { panel },
})

export const delayedOpenPanel = (panel, timeout = 0) => ({
  type: LAYOUT_DELAYED_OPENPANEL,
  payload: { panel, timeout },
})

export const setIsPreview = isPreview => ({
  type: SET_IS_PREVIEW,
  payload: { isPreview },
})

export const onNextFlyerBannerLoad = (isOpen, context) => ({
  type: SET_NEXT_FLYER_OPEN,
  payload: { isOpen, context },
})
