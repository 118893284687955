import {
  TRACKING_AD_FORM,
  adForm,
  VIEWER_INITIALIZED,
  VIEWER_BROWSE,
} from '../../../actions'
import { selectTrackingAdForm, selectUserCookiesPreference } from '../../../selectors'

import { OT_TARGETING_ADS_PREFERENCE } from '../../../../utils/consts'
import { env } from '../../../../utils'

const adFormHandler = () => next => action => {
  if (action.type === TRACKING_AD_FORM) {
    const { args } = action.payload
    if (window.adf) window.adf.track(...args)
  }

  next(action)
}

const onViewerInitialized = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)
  const accessPixelEvent = env('REACT_APP_ADFORM_PIXEL_ACCESS_EVENT', false)

  if (action.type === VIEWER_INITIALIZED && isPreferenceExpressed) {
    const adFormPixels = selectTrackingAdForm(state)

    adFormPixels.forEach(pixel => {
      dispatch(adForm(pixel, 'Pixel Apertura Volantino'))

      if (accessPixelEvent) {
        dispatch(adForm(pixel, accessPixelEvent))
      }
    })
  }
}

const onViewerBrowse = ({ getState, dispatch }) => next => action => {
  next(action)
  const state = getState()
  const isPreferenceExpressed = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

  if (action.type === VIEWER_BROWSE && isPreferenceExpressed) {
    const adFormPixels = selectTrackingAdForm(state)

    adFormPixels.forEach(pixel => (
      dispatch(adForm(pixel, 'Pixel Sfoglio Volantino'))
    ))
  }
}

export default [
  adFormHandler,
  onViewerInitialized,
  onViewerBrowse,
]
