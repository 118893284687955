import { env, getEnvFeatureToggle } from '../../utils'

const trackingState = {
  adwords: {
    arbitrage: [{
      id: env('REACT_APP_GOOGLE_AW_ARBITRAGE_0_ID'),
      label: env('REACT_APP_GOOGLE_AW_ARBITRAGE_0_LABEL'),
    }],
    remarketing: [{
      id: env('REACT_APP_GOOGLE_AW_REMARKETING_ID'),
    }],
    extn: [{
      id: env('REACT_APP_GOOGLE_AW_EXTN_ID', false),
    }],
    pagesPercentage: {
      30: {
        id: env('REACT_APP_GOOGLE_AW_PERCENTAGE_30_ID'),
        label: env('REACT_APP_GOOGLE_AW_PERCENTAGE_30_LABEL'),
      },
      50: {
        id: env('REACT_APP_GOOGLE_AW_PERCENTAGE_50_ID'),
        label: env('REACT_APP_GOOGLE_AW_PERCENTAGE_50_LABEL'),
      },
      80: {
        id: env('REACT_APP_GOOGLE_AW_PERCENTAGE_80_ID'),
        label: env('REACT_APP_GOOGLE_AW_PERCENTAGE_80_LABEL'),
      },
    },
  },
  analytics: [
    env('REACT_APP_GOOGLE_ANALYTICS_4_0_ID'),
  ],
  facebook: [
    env('REACT_APP_FACEBOOK_PIXEL_0'),
  ],
  doubleclick: {
    id: env('REACT_APP_DOUBLECLICK_ID'),
    type: env('REACT_APP_DOUBLECLICK_TYPE'),
    cat: env('REACT_APP_DOUBLECLICK_CAT'),
  },
  adform: [],
  comScore: {},
  isPixelsInitialized: false,
}

if (getEnvFeatureToggle('REACT_APP_FEATURE_TOGGLE_ADFORM')) {
  trackingState.adform.push(env('REACT_APP_ADFORM_PIXEL_0'))
}

if (getEnvFeatureToggle('REACT_APP_FEATURE_TOGGLE_COMSCORE')) {
  trackingState.comScore = {
    c1: env('REACT_APP_COMSCORE_C1'),
    c2: env('REACT_APP_COMSCORE_C2'),
  }
}

export default trackingState
