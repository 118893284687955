import { PureComponent } from 'react'

import { env } from '../../utils'

export default class NotFound extends PureComponent {
  static redirect() {
    window.location = `https://${env('REACT_APP_WEBSITE_URL')}`
  }

  componentDidMount() {
    const timeout = Number.parseInt(env('REACT_APP_404_REDIRECT_TIMEOUT'), 10)
    setTimeout(NotFound.redirect, timeout)
  }

  render() {
    return <div />
  }
}
