import queryString from 'query-string'
import { compile } from 'path-to-regexp'
import { push } from 'connected-react-router'

import {
  PANEL_CROSSELL,
  PANEL_APP_DOWNLOAD,
  PANEL_SMARTBANNER,
  viewerExit,
  openPanel,
  closePanel,
  tableOfContentsInitialized,
} from '../../actions'

import { env } from '../../../utils'
import { selectRouterLocation, selectFlyerData } from '../../selectors'

import { flyerPath, flyerPathV2 } from '../../../routes'

import { handleUXEvent } from '../helpers'

const onFlyerCardClickInCrossell = ({ getState, dispatch }, { payload }) => {
  const state = getState()
  const { event: { flyerId, utmMediumValue } } = payload
  const { pathname } = selectRouterLocation(state)

  dispatch(viewerExit())

  const { spotlight } = selectFlyerData(flyerId)(state)
  if (spotlight) {
    return
  }

  const search = queryString.parse(selectRouterLocation(state).search)
  const flyerPathTo = pathname.includes('v2') ? flyerPathV2 : flyerPath
  const pathTo = compile(flyerPathTo)
  const flyerPage = 1

  dispatch(push({
    pathname: pathTo({ flyerId, flyerPage }),
    search: queryString.stringify({ ...search, utm_medium: utmMediumValue || 'cs' }),
  }))
  dispatch(tableOfContentsInitialized())
}

const onSmartBannerClose = ({ dispatch }) => {
  dispatch(closePanel(PANEL_SMARTBANNER))
}

const onSmartBannerClick = ({ getState, dispatch }, { payload }) => {
  const state = getState()

  const queryParams = queryString.parse(selectRouterLocation(state).search)

  const { direct = 'true' } = queryParams

  if (direct === 'true') {
    const { flyerId } = payload.event
    const { retailer } = selectFlyerData(flyerId)(state)
    // eslint-disable-next-line camelcase
    const { utm_id = '', appDownload = '', utm_source = '' } = queryParams

    const link = env('REACT_APP_APPDOWNLOAD_PRIMARY_LINK')

    // eslint-disable-next-line camelcase
    const toOpen = `${link}&${queryString.stringify({
      c: retailer.slug,
      af_ad: flyerId,
      af_adset: utm_id,
      af_ad_type: appDownload,
      af_channel: utm_source,
    })}`

    window.open(toOpen, '_blank')
  } else {
    dispatch(openPanel(PANEL_APP_DOWNLOAD))
  }
}

export default [
  handleUXEvent({
    name: 'click',
    target: 'flyerCard',
    context: PANEL_CROSSELL,
  }, onFlyerCardClickInCrossell),
  handleUXEvent({
    name: 'click',
    target: 'smartBanner',
  }, onSmartBannerClick),
  handleUXEvent({
    name: 'close',
    target: 'smartBanner',
  }, onSmartBannerClose),
]
