import PropTypes from 'prop-types'
import { compile } from 'path-to-regexp'
import { Route, Redirect } from 'react-router-dom'

/**
 * Renders a redirect route parsing the pathTo params to be reused in to params
 * see issue: https://stackoverflow.com/questions/43399740/react-router-redirect-drops-param
 */
const RedirectWithParams = ({
  exact, from, push, to, preserveSearch,
}) => {
  const pathTo = compile(to)
  /* eslint-disable react/prop-types */
  const component = ({ match: { params }, location }) => {
    const search = preserveSearch && location ? location.search : ''
    const redirectTo = `${pathTo(params)}${search}`
    return <Redirect to={redirectTo} push={push} />
  }
  /* eslint-enable */
  return <Route exact={exact} path={from} component={component} />
}

RedirectWithParams.propTypes = {
  exact: PropTypes.bool,
  from: PropTypes.string.isRequired,
  push: PropTypes.bool,
  to: PropTypes.string.isRequired,
  preserveSearch: PropTypes.bool,
}

RedirectWithParams.defaultProps = {
  exact: true,
  push: false,
  preserveSearch: true,
}

export default RedirectWithParams
