import { TRACKING_GTAG } from '../../../actions'

const gtagHandler = (/* state */) => next => action => {
  next(action)

  if (action.type === TRACKING_GTAG) {
    const { args } = action.payload
    if (window.gtag) window.gtag(...args)
  }
}

export default [
  gtagHandler,
]
