import {
  PANEL_CROSSELL,
  VIEWER_BROWSE,
  VIEWER_SWIPE_LAST,
  gtag,
} from '../../../actions'
import {
  selectTrackingGAnalytics,
  selectViewerFlyerId,
  selectFlyerData,
} from '../../../selectors'

import { handleUXEvent, takeEvery } from '../../helpers'

const onFlyerCardClickInCrossell = ({ getState, dispatch }, action) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  const { event: { flyerId } } = action.payload
  const flyer = selectFlyerData(flyerId)(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'Click Flyers', {
      send_to: id,
      event_category: 'CrossSell',
      event_label: flyer.retailer.slug,
      value: flyerId,
    }))
  ))
}

const onCrossellOpen = ({ getState, dispatch }, action) => {
  // Wait until we have the flyer's data
  const interval = setInterval(() => {
    const state = getState()
    const { event: { flyerId } } = action.payload
    const flyer = selectFlyerData(flyerId)(state)

    if (flyerId && flyer) {
      clearInterval(interval)
      const gAnalyticsIds = selectTrackingGAnalytics(state)

      gAnalyticsIds.forEach(id => (
        dispatch(gtag('event', 'Impression', {
          send_to: id,
          event_category: 'CrossSell',
          event_label: flyer.retailer.slug,
          value: flyerId,
        }))
      ))
    }
  }, 1000)
}

const onCrossellClose = ({ getState, dispatch }, action) => {
  // Wait until we have the flyer's data
  const interval = setInterval(() => {
    const state = getState()
    const { event: { flyerId } } = action.payload
    const flyer = selectFlyerData(flyerId)(state)

    if (flyerId && flyer) {
      clearInterval(interval)
      const gAnalyticsIds = selectTrackingGAnalytics(state)

      gAnalyticsIds.forEach(id => (
        dispatch(gtag('event', 'Close PopUp', {
          send_to: id,
          event_category: 'CrossSell',
          event_label: flyer.retailer.slug,
        }))
      ))
    }
  }, 1000)
}

const onAppDownloadCtaPrimaryClick = ({ getState, dispatch }) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'OnClick', {
      send_to: id,
      event_category: 'CTA',
      event_label: 'Click AppDWN App',
    }))
  ))
}

const onAppDownloadCtaSecondaryClick = ({ getState, dispatch }) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'OnClick', {
      send_to: id,
      event_category: 'CTA',
      event_label: 'Click AppDWN Web',
    }))
  ))
}

const onAppDownloadOpen = ({ getState, dispatch }) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'openPopUp', {
      send_to: id,
      event_category: 'CTA',
      event_label: 'Impression AppDWN',
    }))
  ))
}

const onAppDownloadClose = ({ getState, dispatch }) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'OnClick', {
      send_to: id,
      event_category: 'CTA',
      event_label: 'Close AppDWN',
    }))
  ))
}

const onSmartBannerClick = ({ getState, dispatch }) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'OnClick', {
      send_to: id,
      event_category: 'CTA',
      event_label: 'Click SmartBanner',
    }))
  ))
}

const onSmartBannerClose = ({ getState, dispatch }) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'OnClick', {
      send_to: id,
      event_category: 'CTA',
      event_label: 'Close SmartBanner',
    }))
  ))
}

const onSmartBannerOpen = ({ getState, dispatch }) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'openPopUp', {
      send_to: id,
      event_category: 'CTA',
      event_label: 'Impression SmartBanner',
    }))
  ))
}

const onViewerNavigatorOpen = ({ getState, dispatch }, { payload }) => {
  const state = getState()
  const { flyerId } = payload.event

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'openViewerNavigator', {
      send_to: id,
      event_category: 'Viewer',
      event_label: flyerId,
    }))
  ))
}

const onViewerNavigatorClose = ({ getState, dispatch }, { payload }) => {
  const state = getState()
  const { flyerId } = payload.event

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'closeViewerNavigator', {
      send_to: id,
      event_category: 'Viewer',
      event_label: flyerId,
    }))
  ))
}

const onViewerLastPage = ({ getState, dispatch }) => next => action => {
  next(action)

  if (action.type === VIEWER_BROWSE) {
    const { isLast } = action.payload.pagesData
    if (isLast) {
      const state = getState()

      const gAnalyticsIds = selectTrackingGAnalytics(state)

      const flyerId = selectViewerFlyerId(state)
      const flyer = selectFlyerData(flyerId)(state)

      gAnalyticsIds.forEach(id => (
        dispatch(gtag('event', 'Landing', {
          send_to: id,
          event_category: 'LastPage',
          event_label: flyer.retailer.slug,
          value: flyerId,
        }))
      ))
    }
  }
}

const onViewerSwipeLast = ({ getState, dispatch }) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  const flyerId = selectViewerFlyerId(state)
  const flyer = selectFlyerData(flyerId)(state)

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', 'Swipe', {
      send_to: id,
      event_category: 'LastPage',
      event_label: flyer.retailer.slug,
      value: flyerId,
    }))
  ))
}

const onCategoriesMenuItemClick = ({ getState, dispatch }, action) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const { event: { name, target, slug } } = action.payload

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', name, {
      send_to: id,
      event_category: target,
      event_label: slug,
    }))
  ))
}

const onCategoriesMenuScroll = ({ getState, dispatch }, action) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const { event: { target, name } } = action.payload

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', name, {
      send_to: id,
      event_category: target,
    }))
  ))
}

const onDvcLogo = ({ getState, dispatch }, action) => {
  const state = getState()
  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const { event: { name } } = action.payload

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', name, {
      send_to: id,
      event_category: 'mainHeader',
    }))
  ))
}

const onRetailerCta = ({ getState, dispatch }, action) => {
  const state = getState()
  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const { event: { name } } = action.payload

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', name, {
      send_to: id,
      event_category: 'mainHeader',
    }))
  ))
}

const onMoreOffersCta = ({ getState, dispatch }, action) => {
  const state = getState()
  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const { event: { name } } = action.payload

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', name, {
      send_to: id,
      event_category: 'flyerFooter',
    }))
  ))
}

const onShowMapCta = ({ getState, dispatch }, action) => {
  const state = getState()
  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const { event: { name } } = action.payload

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', name, {
      send_to: id,
      event_category: 'productPage',
    }))
  ))
}

const onShowAllStoresCta = ({ getState, dispatch }, action) => {
  const state = getState()
  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const { event: { name } } = action.payload

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', name, {
      send_to: id,
      event_category: 'productPage',
    }))
  ))
}

const onStoreNumberCta = ({ getState, dispatch }, action) => {
  const state = getState()
  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const { event: { name } } = action.payload

  gAnalyticsIds.forEach(id => (
    dispatch(gtag('event', name, {
      send_to: id,
      event_category: 'productPage',
    }))
  ))
}

const onStoreLinkClickInLanding = ({ getState, dispatch }, action) => {
  const state = getState()

  const gAnalyticsIds = selectTrackingGAnalytics(state)

  const {
    event: {
      storeId = null, type, flyerId = null, flyerGroupId = null,
      utmSource = null, utmCampaign = null,
    },
  } = action.payload

  gAnalyticsIds.forEach(id => {
    dispatch(gtag('event', `Click Store ${type}`, {
      send_to: id,
      event_category: `Landing Geolocalizzata ${type}`,
      event_label: `${flyerGroupId};${flyerId};${storeId}`,
    }))

    dispatch(gtag('event', `Click Store ${type}`, {
      send_to: id,
      event_category: `intermediate ${type} page click`,
      event_label: `${utmCampaign};${utmSource}`,
    }))
  })
}

const onFlyerImpressionFromClickInLanding = ({ getState, dispatch }, action) => {
  const state = getState()
  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const {
    event: {
      search,
    },
  } = action.payload
  const {
    clickFrom = null, clickFromValue = null,
  } = search
  if (clickFrom) {
    gAnalyticsIds.forEach(id => (
      dispatch(gtag('event', 'Flyer Read', {
        send_to: id,
        event_category: clickFrom,
        event_label: clickFromValue,
      }))
    ))
  }
}

const onViewLandingGeo = ({ getState, dispatch }, action) => {
  const state = getState()
  const gAnalyticsIds = selectTrackingGAnalytics(state)
  const {
    event: {
      utmSource = null, utmCampaign = null, type = null,
    },
  } = action.payload
  gAnalyticsIds.forEach(id => {
    dispatch(gtag('event', 'landingGeo page view', {
      send_to: id,
      event_category: `intermediate ${type} page view`,
      event_label: `${utmCampaign};${utmSource}`,
    }))
  })
}

export default [
  handleUXEvent({
    name: 'click',
    target: 'flyerCard',
    context: PANEL_CROSSELL,
  }, onFlyerCardClickInCrossell),
  handleUXEvent({
    name: 'open',
    target: PANEL_CROSSELL,
  }, onCrossellOpen),
  handleUXEvent({
    name: 'close',
    target: PANEL_CROSSELL,
  }, onCrossellClose),
  handleUXEvent({
    name: 'click_primary',
    target: 'appDownload',
  }, onAppDownloadCtaPrimaryClick),
  handleUXEvent({
    name: 'click_secondary',
    target: 'appDownload',
  }, onAppDownloadCtaSecondaryClick),
  handleUXEvent({
    name: 'open',
    target: 'appDownload',
  }, onAppDownloadOpen),
  handleUXEvent({
    name: 'close',
    target: 'appDownload',
  }, onAppDownloadClose),
  handleUXEvent({
    name: 'click',
    target: 'smartBanner',
  }, onSmartBannerClick),
  handleUXEvent({
    name: 'open',
    target: 'smartBanner',
  }, onSmartBannerOpen),
  handleUXEvent({
    name: 'close',
    target: 'smartBanner',
  }, onSmartBannerClose),
  handleUXEvent({
    name: 'open',
    target: 'viewerNavigator',
  }, onViewerNavigatorOpen),
  handleUXEvent({
    name: 'close',
    target: 'viewerNavigator',
  }, onViewerNavigatorClose),
  handleUXEvent({
    name: 'itemClick',
    target: 'categoriesMenu',
  }, onCategoriesMenuItemClick),
  handleUXEvent({
    name: 'scrollAtEnd',
    target: 'categoriesMenu',
  }, onCategoriesMenuScroll),
  handleUXEvent({
    name: 'scrollFromStart',
    target: 'categoriesMenu',
  }, onCategoriesMenuScroll),
  handleUXEvent({
    name: 'dvcLogoClick',
    target: 'mainHeader',
  }, onDvcLogo),
  handleUXEvent({
    name: 'dvcLogoImpression',
    target: 'mainHeader',
  }, onDvcLogo),
  handleUXEvent({
    name: 'retailerCtaClick',
    target: 'mainHeader',
  }, onRetailerCta),
  handleUXEvent({
    name: 'retailerCtaImpression',
    target: 'mainHeader',
  }, onRetailerCta),
  handleUXEvent({
    name: 'moreOffersCtaClick',
    target: 'flyerFooter',
  }, onMoreOffersCta),
  handleUXEvent({
    name: 'moreOffersCtaImpression',
    target: 'flyerFooter',
  }, onMoreOffersCta),
  handleUXEvent({
    name: 'showMapClick',
    target: 'productPage',
  }, onShowMapCta),
  handleUXEvent({
    name: 'showMapImpression',
    target: 'productPage',
  }, onShowMapCta),
  handleUXEvent({
    name: 'showAllStoresClick',
    target: 'productPage',
  }, onShowAllStoresCta),
  handleUXEvent({
    name: 'showAllStoresImpression',
    target: 'productPage',
  }, onShowAllStoresCta),
  handleUXEvent({
    name: 'storeNumberClick',
    target: 'productPage',
  }, onStoreNumberCta),
  handleUXEvent({
    name: 'storeNumberImpression',
    target: 'productPage',
  }, onStoreNumberCta),
  handleUXEvent({
    name: 'click',
    target: 'linkLandingGeo',
  }, onStoreLinkClickInLanding),
  handleUXEvent({
    name: 'impression',
    target: 'checkIsfromLinkLandingGeo',
  }, onFlyerImpressionFromClickInLanding),
  handleUXEvent({
    name: 'impression',
    target: 'viewLandingGeo',
  }, onViewLandingGeo),
  onViewerLastPage,
  takeEvery(VIEWER_SWIPE_LAST, onViewerSwipeLast),
]
