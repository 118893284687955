import { FETCH_REQUEST, FETCH_RECEIVE, FETCH_ERROR } from '../actions'
import initialState from '../state/fetch.state'

export default function (state = initialState, action) {
  const { requests, responses, errors } = state

  switch (action.type) {
    case FETCH_REQUEST: {
      const requestObj = action.payload
      requests[requestObj.id] = requestObj.request
      return { ...state, requests }
    }

    case FETCH_RECEIVE: {
      const { id } = action.payload
      const request = requests[id]
      responses[id] = request
      delete requests[id]
      return { ...state, responses, requests }
    }

    case FETCH_ERROR: {
      const { id } = action.payload
      const request = requests[id]
      errors[id] = request
      delete requests[id]
      return { ...state, errors, requests }
    }

    default:
      return state
  }
}
