import isEmpty from 'lodash.isempty'
import { sanitizeId, getUnixTimestampFromDate } from '../../../../utils'

/**
 * Utility to filter Flyer.settings
 */
export function filterAllowedFlyerSettings(settings, allowedSettings) {
  if (isEmpty(settings) || isEmpty(allowedSettings)) {
    return null
  }

  return Object.keys(settings)
    .filter(key => allowedSettings.includes(key))
    .reduce((obj, key) => ({
      ...obj,
      [key]: settings[key],
    }), {})
}

/**
* Utility fn used to apply modification to fetched flyer data
*/
export function normalizeFlyerData(data) {
  const flyer = { ...data }
  flyer.id = sanitizeId(flyer.id)
  // categories don't get always fetched
  if (flyer.categories) {
    flyer.categories = flyer
      .categories
      .map(category => ({
        ...category,
        id: sanitizeId(category.id),
      }))
  }

  if (flyer.retailer) delete flyer.retailer

  if (flyer.settings) {
    const [spotlight] = flyer.settings.spotlight || []
    const { tracking_url_client: trackingUrlClient = null } = flyer.settings
    flyer.spotlight = spotlight
    if (typeof trackingUrlClient === 'string' && trackingUrlClient.length > 0) {
      flyer.trackingUrlClient = trackingUrlClient.replace('{{timestamp}}', getUnixTimestampFromDate(new Date()))
    }
    flyer.table_of_contents = flyer.settings.table_of_contents || []

    flyer.settings = filterAllowedFlyerSettings(flyer.settings, ['ctas', 'hide_unpublish_date'])
  }

  return flyer
}

/**
 * Utility fn used to apply normalization to fetched flyers data
 */
export function normalizeFlyersData(flyers) {
  return flyers.map(normalizeFlyerData)
}
