import queryString from 'query-string'
import * as Sentry from '@sentry/browser'
import { env } from '.'

const environment = env('REACT_APP_ENV')
const isDebugEnabled = queryString.parse(document.location.search).debug === 'true'
const sentryDsn = env('REACT_APP_SENTRY_TRACKING_DSN', false)

export const errorTrackingInit = () => {
  if (!sentryDsn || (environment !== 'prod' && !isDebugEnabled)) return

  Sentry.init({
    environment,
    dsn: sentryDsn,
    release: env('REACT_APP_VERSION'),
  })
}
