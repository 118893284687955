import I18nUtils from '@doveconviene/sf-web-js-sdk/i18n/I18nUtils'

import env from './env'
import webStorage from './webStorage'
import { parseLocationSearch } from '.'

// get default geolocation values
const i18n = new I18nUtils(null, {}, {})
const country = env('REACT_APP_COUNTRY').toLowerCase()
const consts = i18n.getI18nConsts(country)
const defaultGeolocation = {
  lat: consts.geolocation.lat.toString(),
  lng: consts.geolocation.lng.toString(),
}

/**
 * Returns prerecorded geolocation or defaultGeolocation
 */
const getGeolocation = () => {
  // First, look into query string
  const { search } = window.location
  const { lat, lng } = parseLocationSearch(search)

  if (lat && lng) return { lat, lng }

  // Then, if nothing is found, look inside webStorage
  const geolocation = webStorage.getItem('userGeolocation')

  if (!geolocation) {
    webStorage.setItem(
      'userGeolocation',
      JSON.stringify(defaultGeolocation),
    )

    return defaultGeolocation
  }

  try {
    return JSON.parse(geolocation)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Unable to parse userGeolocation from webStorage, falling back to defaultGeolocation')
    return defaultGeolocation
  }
}

export default getGeolocation
