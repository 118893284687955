import createFetchRestData from '@doveconviene/sf-web-js-sdk/createFetchRestData'
import createFetchCache from '@doveconviene/sf-web-js-sdk/createFetchCache'
import createFetchQueue from '@doveconviene/sf-web-js-sdk/createFetchQueue'
import Cache from '@doveconviene/sf-web-js-sdk/cache'
import env from './env'

// The development server proxies API requests
// https://facebook.github.io/create-react-app/docs/proxying-api-requests-in-development
const baseUrl = env('NODE_ENV') === 'development' ? '' : env('REACT_APP_API_BASEURL')

const version = env('REACT_APP_API_VERSION')
const country = env('REACT_APP_COUNTRY').toLowerCase()
const key = env('REACT_APP_API_KEY')
const auth = env('REACT_APP_API_AUTH')

const fetchFn = async (...args) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const res = await fetch(...args)
    if (res.status !== 200) throw new Error('Response returned status different from 200')
    const json = await res.json()
    return json
  } catch (e) {
    throw e
  }
}

const queueOptions = {
  verbose: env('NODE_ENV') === 'development',
  concurrency: 10,
  failOnError: true,
}

const urlOptions = {
  baseUrl: `${baseUrl}/${version}/${country}`,
  key,
}

const fetchParams = {
  headers: {
    Authorization: `Basic ${btoa(auth)}`,
  },
}

const cache = new Cache()
const fetchCache = createFetchCache(fetchFn, cache)
const fetchQueue = createFetchQueue(fetchCache, queueOptions)
const fetchRestData = createFetchRestData(fetchQueue, urlOptions, fetchParams)

export default fetchRestData
