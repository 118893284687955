import { keyframes } from '@emotion/core'

const slideInUp = keyframes({
  from: {
    transform: 'translate3d(0, 100%, 0)',
    visibility: 'visible',
  },
  to: {
    transform: 'translate3d(0, 0, 0)',
  },
})

const slideInDown = keyframes({
  from: {
    transform: 'translate3d(0, -100%, 0)',
    visibility: 'visible',
  },
  to: {
    transform: 'translate3d(0, 0, 0)',
  },
})

const slideInLeft = keyframes({
  from: {
    transform: 'translate3d(-100%, 0, 0)',
    visibility: 'visible',
  },
  to: {
    transform: 'translate3d(0, 0, 0)',
  },
})

const slideInRight = keyframes({
  from: {
    transform: 'translate3d(100%, 0, 0)',
    visibility: 'visible',
  },
  to: {
    transform: 'translate3d(0, 0, 0)',
  },
})

const fadeInDown = keyframes({
  from: {
    opacity: 0,
    transform: 'translate(0, -100%)',
  },
  to: {
    opacity: 1,
    transform: 'translate(0, 0)',
  },
})

const fadeInDownCentered = keyframes({
  from: {
    opacity: 0,
    transform: 'translate(-50%, -100%)',
  },
  to: {
    opacity: 1,
    transform: 'translate(-50%, -50%)',
  },
})

export default {
  slideInUp,
  slideInDown,
  slideInLeft,
  slideInRight,
  fadeInDown,
  fadeInDownCentered,
}
