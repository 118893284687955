import { request } from '../../../actions/fetch.actions'
import { normalizeCategoriesData } from './sanitizer'
import { setCategories } from '../../../actions/categories.actions'
import { selectFetchResponse } from '../../../selectors/fetch.selectors'

export function categoriesRequestId(id, type = 'categories') {
  return `${type}/${id}`
}

export function fetchCategoriesParams() {
  return {
    resource: 'categories',
    paginate: false,
    params: {
      limit: 100,
      fields: 'id,name,slug,priority,is_home,is_highlight',
    },
    populate: category => ({
      assets: {
        resource: `categories/${category.id}/assets`,
        params: {
          conditions: {
            name: 'svg',
          },
        },
      },
    }),
  }
}

/**
 * Dispatched when a categories request completes successfully
 */
export function receiveCategoriesData(data) {
  const normalizedCategories = normalizeCategoriesData(data)
  return dispatch => {
    dispatch(setCategories(normalizedCategories))
  }
}

/**
 * Fetches remote data for all categories
 */
export function fetchCategories() {
  return dispatch => {
    const rId = categoriesRequestId('all')
    const params = fetchCategoriesParams()

    return dispatch(request(rId, params, receiveCategoriesData))
  }
}

/**
 * Checks to see if categories have already been fetched
 * if not launches fetch
 */
export function getCategoriesMenu() {
  return (dispatch, getState) => {
    // we don't know how many the categories should be so we check if the response
    // has been received
    const state = getState()
    const response = selectFetchResponse(categoriesRequestId('all'))(state)

    if (!response) {
      dispatch(fetchCategories())
    }
  }
}
