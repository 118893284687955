import add from 'date-fns/add'
import sub from 'date-fns/sub'
import isFuture from 'date-fns/isFuture'
import isToday from 'date-fns/isToday'
import isWithinInterval from 'date-fns/isWithinInterval'
import { getExpirationText } from './index'

const getExpiringStatus = (startDate, endDate, hideExpiringStatus) => {
  const today = new Date()
  const flyerStartDate = new Date(startDate)
  const flyerEndDate = new Date(endDate)
  if (flyerStartDate.toString() === 'Invalid Date' || flyerEndDate.toString() === 'Invalid Date') return null

  let expiringStatus = ''
  if (isFuture(flyerStartDate)) {
    expiringStatus = 'coming-soon'
  } else if (isToday(flyerStartDate)
    || isWithinInterval(
      today,
      { start: flyerStartDate, end: add(flyerStartDate, { days: 3 }) },
    )) {
    expiringStatus = 'new'
  } else if (!hideExpiringStatus
    && (isToday(flyerEndDate)
    || isWithinInterval(today, { start: sub(flyerEndDate, { days: 5 }), end: flyerEndDate }))) {
    expiringStatus = 'on-expiring'
  }

  return expiringStatus
}

const getFlyerExpirationData = ({ startDate, endDate, hideUnpublishDate }) => {
  let expirationText = ''
  let expiringStatus = ''

  const hideExpiration = !!Number(hideUnpublishDate || 0)
  if (endDate) {
    expirationText = !hideExpiration ? getExpirationText(endDate) : ''
  }
  if (startDate && endDate) {
    expiringStatus = getExpiringStatus(startDate, endDate, hideUnpublishDate)
  }

  return {
    expirationText,
    expiringStatus,
  }
}

export default getFlyerExpirationData
