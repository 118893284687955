import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

// TODO: generate/retrieve a unique id to generate unique keys  with multiple instances

const StyledRoot = styled('ul')({
  padding: 0,
  margin: 0,
  listStyle: 'none',
})

export default class List extends Component {
  /**
   * Renders the single item through the template function renderItem provided
   */
  renderListItem(item, index) {
    const { renderItem } = this.props

    return (
      <li key={`listItem-${index}`}>
        {renderItem(item, index, this)}
      </li>
    )
  }

  render() {
    const { data } = this.props
    return (
      <StyledRoot>
        {data.map(this.renderListItem, this)}
      </StyledRoot>
    )
  }
}

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  renderItem: PropTypes.func.isRequired,
}
