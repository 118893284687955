export const selectTracking = state => state.tracking
export const selectTrackingGAW = state => selectTracking(state).adwords
export const selectTrackingGAnalytics = state => selectTracking(state).analytics
export const selectTrackingFB = state => selectTracking(state).facebook
export const selectTrackingAdForm = state => selectTracking(state).adform
export const selectTrackingDoubleclick = state => selectTracking(state).doubleclick

export const selectTrackingGAWArbitrage = state => (
  selectTrackingGAW(state).arbitrage
)
export const selectTrackingGAWArbitrageAt = id => state => (
  selectTrackingGAW(state).arbitrage[id]
)
export const selectTrackingGAWRemarketing = state => (
  selectTrackingGAW(state).remarketing
)
export const selectTrackingGAWRemarketingAt = id => state => (
  selectTrackingGAW(state).remarketing[id]
)
export const selectTrackingGAWPagesPercentage = state => (
  selectTrackingGAW(state).pagesPercentage
)
export const selectTrackingGAWPagesPercentageAt = id => state => (
  selectTrackingGAW(state).pagesPercentage[id]
)
export const selectTrackingGAWExtn = state => (
  selectTrackingGAW(state).extn
)

export const selectTrackingGAnalyticsAt = id => state => selectTrackingGAnalytics(state)[id]

export const selectTrackingFBAt = id => state => selectTrackingFB(state)[id]

export const selectTrackingAdFormAt = id => state => selectTrackingAdForm(state)[id]

export const selectTrackingComScore = state => selectTracking(state).comScore

export const selectIsPixelsInitialized = state => selectTracking(state).isPixelsInitialized
