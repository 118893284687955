/* eslint-disable camelcase */
import { SET_RETAILER, SET_RETAILERS, SET_RETAILER_ASSETS } from '../actions'
import initialState from '../state/retailers.state'

export default function (state = initialState, action) {
  const { list, assets } = state

  switch (action.type) {
    case SET_RETAILER:
      if (action.payload) {
        const { retailer } = action.payload
        list[retailer.id] = retailer
        return { ...state, list }
      }
      return state

    case SET_RETAILERS:
      if (action.payload) {
        const { retailers } = action.payload
        const newList = retailers.reduce(
          (newRetailers, newRetailer) => ({ ...newRetailers, [newRetailer.id]: newRetailer }),
          list,
        )

        return { ...state, list: newList }
      }
      return state

    case SET_RETAILER_ASSETS:
      if (action.payload) {
        const { assets: newAssets } = action.payload
        const logoTransparent = newAssets.find(asset => asset.name === 'logo_transparent' && !!(asset.url))
        const logoSegnalino = newAssets.find(asset => asset.name === 'logo_segnalino' && !!(asset.url))
        const logoSegnalinoWeb = newAssets.find(asset => asset.name === 'logo_segnalino_web' && !!(asset.url))

        const logo = logoSegnalino || logoTransparent
        const retailerId = logo?.retailer_id

        return {
          ...state,
          assets: {
            ...assets,
            [retailerId]: {
              logo,
              pin: logoSegnalinoWeb,
            },
          },
        }
      }

      return state

    default:
      return state
  }
}
