import {
  LAYOUT_OPENPANEL,
  LAYOUT_CLOSEPANEL,
  LAYOUT_OPENFULLSCREEN,
  LAYOUT_CLOSEFULLSCREEN,
  LAYOUT_TOGGLEFULLSCREEN,
  SET_IS_PREVIEW,
  SET_NEXT_FLYER_OPEN,
} from '../actions'

import initialState from '../state/layout.state'

export default function (state = initialState, action) {
  switch (action.type) {
    case LAYOUT_OPENPANEL: {
      const { activePanels } = state
      const { panel } = action.payload
      const newPanels = new Set([...activePanels, panel])
      return {
        ...state,
        activePanels: Array.from(newPanels),
      }
    }

    case LAYOUT_CLOSEPANEL: {
      const { activePanels } = state
      const { panel } = action.payload
      const newPanels = new Set(activePanels)

      newPanels.delete(panel)

      return {
        ...state,
        activePanels: Array.from(newPanels),
      }
    }

    case LAYOUT_OPENFULLSCREEN: {
      return {
        ...state,
        fullscreen: true,
      }
    }

    case LAYOUT_CLOSEFULLSCREEN: {
      return {
        ...state,
        fullscreen: false,
      }
    }

    case LAYOUT_TOGGLEFULLSCREEN: {
      const fullscreen = !state.fullscreen
      return {
        ...state,
        fullscreen,
      }
    }

    case SET_IS_PREVIEW: {
      const { isPreview } = action.payload
      return {
        ...state,
        isPreview,
      }
    }

    case SET_NEXT_FLYER_OPEN: {
      const { isOpen, context } = action.payload
      return {
        ...state,
        [`isNextFlyerBanner${(context || '').toUpperCase()}Open`]: isOpen,
      }
    }

    default:
      return state
  }
}
