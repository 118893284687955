import {
  TABLE_OF_CONTENTS_IMPRESSION,
} from '../actions'

import initialState from '../state/tracking.state'

export default function (state = initialState, action) {
  switch (action.type) {
    case TABLE_OF_CONTENTS_IMPRESSION: {
      return {
        ...state,
        tableOfContentImpressionDone: true,
      }
    }

    default:
      return state
  }
}
