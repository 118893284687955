import { UX_EVENT } from '../actions'

import initialState from '../state/ux.state'

export default function (state = initialState, action) {
  switch (action.type) {
    case UX_EVENT: {
      const { event } = action.payload
      const { events } = state

      return {
        ...state,
        events: [event, ...events],
      }
    }
    default:
      return state
  }
}
