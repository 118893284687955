import { request } from '../../../actions/fetch.actions'
import { setRetailer, setRetailers, setRetailerAssets } from '../../../actions/retailers.actions'
import { sanitizeRetailerData, sanitizeRetailersData } from './sanitizer'

export function receiveRetailerData(data) {
  let { retailer } = data
  retailer = sanitizeRetailerData(retailer)
  return dispatch => dispatch(setRetailer(retailer))
}

export function receiveRetailersData(data) {
  const retailers = sanitizeRetailersData(data)
  return dispatch => dispatch(setRetailers(retailers))
}

export function fetchRetailerParams(retailerId) {
  return {
    resource: `retailers/${retailerId}`,
    paginate: false,
  }
}

export function fetchRetailer(retailerId) {
  return dispatch => {
    const params = fetchRetailerParams(retailerId)
    return dispatch(request(`retailers/${retailerId}`, params, setRetailer))
  }
}

export function fetchRetailerAssetsParams(retailerId) {
  return {
    resource: `retailers/${retailerId}/assets`,
    paginate: false,
    params: {
      fields: 'id,retailer_id,name,url',
    },
  }
}

export function fetchRetailerAssets(retailerId) {
  return dispatch => {
    const params = fetchRetailerAssetsParams(retailerId)
    return dispatch(request(`retailer/${retailerId}/assets`, params, setRetailerAssets))
  }
}
