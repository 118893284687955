// https://developers.google.com/analytics/devguides/collection/gtagjs/
export default id => (
  new Promise((resolve, reject) => {
    const gtagLibraryScript = document.createElement('script')
    gtagLibraryScript.src = `https://www.googletagmanager.com/gtag/js?id=${id}`
    gtagLibraryScript.async = true

    gtagLibraryScript.onload = resolve
    gtagLibraryScript.onerror = reject

    document.getElementsByTagName('head')[0].appendChild(gtagLibraryScript)
  })
)
