import React from 'react'

function lazyfy(factory, preload = false) {
  const Component = React.lazy(factory)
  if (preload) Component.preload = factory

  return Component
}

export default lazyfy
