import { sanitizeId } from '../../../../utils'

function categoryExceptions(category) {
  if (category.is_highlight === true) {
    return { ...category, slug: '' }
  }
  return category
}

export function normalizeCategoryData(category) {
  return categoryExceptions({
    ...category,
    id: sanitizeId(category.id),
    priority: category.priority ? parseInt(category.priority, 10) : undefined,
    flyers: category.flyers || [],
  })
}

export function normalizeCategoriesData(categories) {
  return categories
    .map(normalizeCategoryData)
    .reduce((cats, cat) => {
      const catId = sanitizeId(cat.id)
      return {
        ...cats,
        [catId]: normalizeCategoryData(cat),
      }
    }, {})
}
