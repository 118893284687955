export const TRACKING_SPLUNK = 'TRACKING/SPLUNK'
export const TRACKING_SPLUNK_POST = 'TRACKING/SPLUNK_POST'
export const TRACKING_GTAG = 'TRACKING/GTAG'
export const TRACKING_FB_Q = 'TRACKING/FB_Q'
export const TRACKING_AD_FORM = 'TRACKING/AD_FORM'
export const TRACKING_COMSCORE_BEACON = 'TRACKING/COMSCORE_BEACON'
export const ONETRUST_ON_READY = 'TRACKING/ONETRUST_ON_READY'
export const ONETRUST_ACTIVATION_DONE = 'TRACKING/ONETRUST_ACTIVATION_DONE'
export const TABLE_OF_CONTENTS_IMPRESSION = 'TRACKING/TABLE_OF_CONTENTS_IMPRESSION'
export const TABLE_OF_CONTENTS_DIALOG_IMPRESSION = 'TRACKING/TABLE_OF_CONTENTS_DIALOG_IMPRESSION'

export const trackSplunk = entries => ({
  type: TRACKING_SPLUNK,
  payload: { entries },
})

export const trackSplunkPost = (eventType, at) => ({
  type: TRACKING_SPLUNK_POST,
  payload: { eventType, at },
})

export const gtag = (...args) => ({
  type: TRACKING_GTAG,
  payload: { args },
})

export const fbQ = (...args) => ({
  type: TRACKING_FB_Q,
  payload: { args },
})

export const adForm = (...args) => ({
  type: TRACKING_AD_FORM,
  payload: { args },
})

export const comScoreBeacon = (...args) => ({
  type: TRACKING_COMSCORE_BEACON,
  payload: { args },
})

export const oneTrustOnReady = () => ({
  type: ONETRUST_ON_READY,
  payload: {},
})

export const oneTrustActivationDone = () => ({
  type: ONETRUST_ACTIVATION_DONE,
  payload: {},
})

export const sendTableOfContentsImpression = () => ({
  type: TABLE_OF_CONTENTS_IMPRESSION,
  payload: {},
})
