export default function parseQueryString(search) {
  const paramStrings = (
    search.charAt(0) === '?' ? search.substring(1) : search
  ).split('&')
  return paramStrings.reduce((acc, param) => {
    const [key, value] = param.split('=')
    return {
      ...acc,
      [key]: Number(value) || value,
    }
  }, {})
}
