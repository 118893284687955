import {
  VIEWER_INITIALIZED,
  VIEWER_BROWSE,
} from '../../../actions'
import { selectViewerNumberOfPages, selectUserCookiesPreference } from '../../../selectors'
import { OT_TARGETING_ADS_PREFERENCE } from '../../../../utils/consts'

export const afterViewerBrowsePercentagePages = (goalPercentage, handler) => {
  let pagesCount = 0
  let tracked = false

  return ({ getState, dispatch }) => next => action => {
    next(action)
    const state = getState()
    // eslint-disable-next-line max-len
    const isTargetingAdsPreferenceEnabled = selectUserCookiesPreference(OT_TARGETING_ADS_PREFERENCE)(state)

    if (isTargetingAdsPreferenceEnabled) {
      if (action.type === VIEWER_INITIALIZED) {
        pagesCount = 0
        tracked = false

        const { currentPages } = action.payload.viewerState
        pagesCount += currentPages.length
      }

      if (action.type === VIEWER_BROWSE) {
        const numberOfPages = selectViewerNumberOfPages(state)

        const { currentPages } = action.payload.pagesData
        pagesCount += currentPages.length

        const percentage = (pagesCount / numberOfPages) * 100

        if (percentage >= goalPercentage && !tracked) {
          tracked = true
          handler({ getState, dispatch }, action)
        }
      }
    }
  }
}
