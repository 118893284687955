// Combine all available reducers to a single root reducer.
import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import fetch from './fetch.reducer'
import layout from './layout.reducer'
import flyers from './flyers.reducer'
import retailers from './retailers.reducer'
import stores from './stores.reducer'
import categories from './categories.reducer'
import viewer from './viewer.reducer'
import client from './client.reducer'
import user from './user.reducer'
import tracking from './tracking.reducer'
import ux from './ux.reducer'
import enrichment from './enrichment.reducer'

export const reducers = {
  fetch,
  layout,
  flyers,
  retailers,
  stores,
  categories,
  viewer,
  client,
  user,
  tracking,
  ux,
  enrichment,
}

export default history => combineReducers({
  router: connectRouter(history),
  ...reducers,
})
