import { UX_EVENT } from '../../actions'

const addTimestampToEvent = () => next => action => {
  if (action.type === UX_EVENT) {
    const { event } = action.payload
    next({
      type: UX_EVENT,
      payload: {
        event: {
          ...event,
          '@': new Date().getTime(),
        },
      },
    })
  } else {
    next(action)
  }
}

export default [
  addTimestampToEvent,
]
