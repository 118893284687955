import sortByPriority from '../../utils/sortByPriority'

export const selectCategories = state => state.categories.list

export const selectCategoriesArray = state => {
  const { categories } = state
  return Object
    .keys(categories.list)
    .map(categoryId => categories.list[categoryId])
}

export const selectCategoriesHighlighted = state => selectCategoriesArray(state)
  .filter(category => category.is_highlight === true)

export const selectCategoriesHome = state => selectCategoriesArray(state)
  .filter(category => category.is_home === true)

export const selectCategoriesMenu = state => {
  const highlighted = selectCategoriesHighlighted(state).sort(sortByPriority)
  const home = selectCategoriesHome(state).sort(sortByPriority)
  return highlighted.concat(home)
}

export const selectCategory = categoryId => state => state.categories?.list[categoryId] || {}
