export const breakpoints = [{
  name: 'mini',
  min: 0,
  max: 543,
}, {
  name: 'small',
  min: 544,
  max: 767,
}, {
  name: 'medium',
  min: 768,
  max: 991,
}, {
  name: 'large',
  min: 992,
  max: 1199,
}, {
  name: 'xlarge',
  min: 1200,
  max: 1599,
}, {
  name: 'xxlarge',
  min: 1600,
  max: 1e9,
}]

/**
* Returns the actual breakpoint definition for the current viewport width
*/
export const getBreakpoint = wWidth => {
  const width = wWidth || window.innerWidth
  const bpIndex = breakpoints.findIndex(sb => sb.min <= width && sb.max >= width)
  return breakpoints[bpIndex]
}
